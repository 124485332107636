import React from 'react';
import { useRef, useEffect } from "react";
import BVAPI from '../../api';
import { useLocation } from "react-router-dom";
import { Viewer } from '@photo-sphere-viewer/core';
import { CompassPlugin } from '@photo-sphere-viewer/compass-plugin';


function PhotoSphereViewer() {
    const location = useLocation();
    const photoSphereStage = useRef();

    useEffect(() => {
        (async () => {
            const queryParams = new URLSearchParams(location.search);
            // get this from the query params. 
            const imageFolder = queryParams.get("image-folder");
            const imageFilename = queryParams.get("image-filename");
            const headingDeg = queryParams.get("heading-deg");
            // this is the only thing that changes. Use the image folder and the image filename from customData 
            const thePreSignedImageUrl = await BVAPI.fetchPanoImage(imageFolder, imageFilename);
            console.log(`Image URL: ${thePreSignedImageUrl}`);
            const viewer = new Viewer({
                container: photoSphereStage.current,
                panorama: thePreSignedImageUrl,
                navbar: [
                    'zoom',
                    'move',
                    'caption',
                    'fullscreen',
                ],
                panoData: {
                    poseHeading: headingDeg, // 0 to 360
                },
                plugins: [
                    CompassPlugin,
                ],
            });
        })();
    }, [location]);

    return (
        <div className="photoSphereWrap">
            <div className="photoSphereStage" ref={photoSphereStage} >
            </div>
        </div>
    );
};

export default PhotoSphereViewer;