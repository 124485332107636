/* eslint-disable */
import { StoreContext } from '../../utils/store';
import React, { useContext, useEffect, useState} from "react";
import Button from '@mui/material/Button';
import proj4 from 'proj4';
import BVAPI from '../../api';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const vec3ToSerializedString = (vec3) => {
  const xyz = { x: vec3.x, y: vec3.y, z: vec3.z };
  return JSON.stringify(xyz);
}


function RequestGroundCollection() {
  const appContext = useContext(StoreContext); 
  const [potreeViewer, setPotreeViewer] = appContext.potreeViewer;
  const [activePointcloud, setActivePointcloud] = appContext.activePointcloud;
  const [theStage, setTheStage] = appContext.theStage;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;
  const [activeProject, setActiveProject] = appContext.activeProject;
  
  const [dropDowns, setDropDowns] = useState(null);
  const [step, setStep] = useState('start');
  const [formData, setFormData] = useState({
    lat: '',
    lng: '',
    coord: '',
    created_by: '',
    reason_id: ''
  });

  const [validationErrors, setValidationErrors] = useState([]);

  const handleDropdownChange = (key, e) => {
    setFormData(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }

  const handleTextChange = (key, e) => { 
    setFormData(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }

  const resetRelatedValues = () => { 
    setStep('start');
    setFormData({
      lat: '',
      lng: '',
      coord: '',
      created_by: '',
      reason_id: ''
    });
    // return user interface back to start state in sidebar. 
    setSidebarModules({
      s1a: null, // top sidebar left (toolbar 1)
      s1b: null, //bottom sidebar left (toolbar 1)
      s2a: 'measurements', // top sidebar right (toolbar 2)
      s2b: 'request-ground-collection',// bottom sidebar right (toolbar 2)
    });

    // nice way to remove measurements from the potree scene.
    // we can use this in a lot of other places when needed. 
    potreeViewer.measuringTool.scene.remove(potreeViewer.scene.measurements[0]); // takes out the dot
    potreeViewer.scene.measurements = []; // takes out the reference to the mearsure object. 
  }

  const makeFinalRequest = async (data) => { 
    const response = await BVAPI.createGroundRequestCollection(data);
    // cleans everything else and starts the component state fresh. 
    resetRelatedValues();
  }

  const submitCollectionRequest = async () => { 
    const errors = [];
    const data = { ...formData };
    data['project_name'] = activePointcloud.collection_name;
    data['project_id'] = activeProject.id;

    if (formData.reason_id) {
      data['reason'] = dropDowns.find((obj) => obj.id === formData.reason_id).value;
    } else { 
      // add to the validation array. 
      errors.push('reason');
    }

    if (!formData.created_by) { 
      errors.push('reviewer');
    }

    setValidationErrors(errors);

    // if there are no errors then go ahead and process the request. 
    if (!errors.length) {
      makeFinalRequest(data);
    }
  }

  // use this to get the lat lng of the measurment. 
  const getLatLngOfCoordinate = (measurement) => {
    const proj4Projections = {
      'EPSG:32617': "+proj=utm +zone=17 +datum=WGS84 +units=m +no_defs +type=crs", //  UTM zone 17N
      'EPSG:4978': "+proj=geocent +datum=WGS84 +units=m +no_defs +type=crs", //WGS 84
      'EPSG:4979': "+proj=longlat +datum=WGS84 +no_defs +type=crs", // WGS 84
      'EPSG:32711': "+proj=utm +zone=11 +south +datum=WGS84 +units=m +no_defs +type=crs", // UTM zone 11S
      'EPSG:32611': "+proj=utm +zone=11 +datum=WGS84 +units=m +no_defs +type=crs", // UTM zone 11N 
      'EPSG:4269': "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs +type=crs", // NAD83 full us
      'EPSG:2274': "+proj=lcc +lat_1=36.41666666666666 +lat_2=35.25 +lat_0=34.33333333333334 +lon_0=-86 +x_0=600000 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=0.3048006096012192 +no_defs", //NAD83 TENNESSEE
      'EPSG:32136': "+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=36.4166666666667 +lat_2=35.25 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs +type=crs", //NAD83 ALSO TENNESSEE
      'EPSG:32126': "+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=36.4166666666667 +lat_2=35.25 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs +type=crs", //Remove this. aaron used the typo from your email so it is currently 3126
      'EPSG:26958': "+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +towgs84=-2,0,4,0,0,0,0 +units=us-m +no_defs +type=crs"
    }
    const pointCloudClickCoordinates = [measurement.points[0].position.x, measurement.points[0].position.y ];
    console.log(activePointcloud.crs);
    return proj4(proj4Projections[activePointcloud.crs], '+proj=longlat +datum=WGS84 +ellps=GRS80', pointCloudClickCoordinates);
  }


  const pointcloudContext = () => {
    setSidebarModules({
      s1a: null, // top sidebar left (toolbar 1)
      s1b: null, //bottom sidebar left (toolbar 1)
      s2a: null, // top sidebar right (toolbar 2)
      s2b: 'request-ground-collection',// bottom sidebar right (toolbar 2)
    });
  
    const measurement = potreeViewer.measuringTool.startInsertion({
      showDistances: false,
      showAngles: false,
      showCoordinates: false,
      showArea: false,
      closed: true,
      maxMarkers: 1,
      name: 'Point'
    });

    measurement.addEventListener('marker_dropped', (event) => {
      console.log(getLatLngOfCoordinate(event.target));
      setFormData({
        lat: getLatLngOfCoordinate(event.target)[1],
        lng: getLatLngOfCoordinate(event.target)[0],
        coord: vec3ToSerializedString(event.target.points[0].position)
      });
    });
    
    setStep('collect-data');
  }
  const getDropdownData = async () => { 
    const theData = await BVAPI.getGroundCollectionDropdowns();
    setDropDowns(theData['request_reasons']);
  }
  

  const beginRequestProcess = async () => {
    console.log("collect some data.");
    console.log(potreeViewer);
    if (activePointcloud && theStage === 'pointcloud') {
      // this handles the functionality when we are in the pointcloud viewer. 
      pointcloudContext();
    }

    if (theStage === 'map') { 
      console.log('we are treating this as a map capture.');
      // when you click the map get the lat lng. 
    }
  }
  
  useEffect(() => {
    const response = getDropdownData();
    
  }, []);

  if (step === 'start') { 
    return (
      <div className="request-ground-collect-wrapper">
        <Button variant="outlined" onClick={beginRequestProcess}>Request Ground Collect</Button>
      </div>
    );
  }

  if (step === 'collect-data') { 
    return (
      <div className="request-ground-collect-wrapper">
        <br />
        <br />
        <FormControl variant="standard" margin="dense" >
          <TextField error={ validationErrors.includes('reviewer') } id="component-simple" label="Reviewer" value={formData.created_by} onChange={(e) => { handleTextChange('created_by', e) }}  />
        </FormControl>
        <FormControl fullWidth margin="dense" error={ validationErrors.includes('reason') } >
          <InputLabel id="demo-simple-select-label">Reason</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.reason_id}
            label="Pole Material"
            onChange={(e) => { handleDropdownChange('reason_id', e)}}
          >
            {dropDowns.map((menuItem, index) => (
              <MenuItem key={index} value={menuItem.id}>{menuItem.value}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <p> { formData.lat !== '' ? `Lat: ${formData.lat}` : ''}</p>
        <p> {formData.lng !== '' ? `Lng: ${formData.lng}` : ''}</p>
        <Button variant="outlined" onClick={submitCollectionRequest}>Submit Collection Request</Button>
      </div>
    )
  }

}

export default RequestGroundCollection;
 