/* eslint-disable */
// will load on map-one route. 
import React, { useState, useContext, useEffect } from "react";
import Stage from '../features/stage/Stage';
import { StoreContext } from '../utils/store';
import { useLocation, useNavigate } from "react-router-dom";
import BVAPI from "../api";


const MapOne = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project-id");
  const appContext = useContext(StoreContext);
  const [layout, setLayout] = appContext.layout;
  const [theStage, setTheStage] = appContext.theStage;
  const [activeProject, setActiveProject] = appContext.activeProject;
  const [availableProjects, setAvailableProjects] = appContext.availableProjects;
  
  const fetchDataIfNeeded = async () => { 
    if (!availableProjects.length) {
      const projectList = await BVAPI.fetchProjectList();
      setAvailableProjects(projectList);
      const filteredArray = projectList.filter(obj => obj.collection_name === projectId);
      console.log(filteredArray);
      await setActiveProject(filteredArray[0]);
      // setSidebarModules({ s1a: 'map' });
    }
    
  }

  
  useEffect(() => {
    fetchDataIfNeeded();
    setLayout('layout-full-stage');
    setTheStage('map');
  }, []);

  return (
    <div className="stage">
      <Stage />
    </div>
  );
}

export default MapOne;
