/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from '../../utils/store';
import BVAPI from "../../api";
import Button from '@mui/material/Button';
import SquareFootIcon from '@mui/icons-material/SquareFoot';





const vec3ToSerializedString = (vec3) => { 
  const xyz = { x: vec3.x, y: vec3.y, z: vec3.z };
  return JSON.stringify(xyz);
}


const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

const metersToFeet = (meters) => {
  // The conversion rate from meters to feet.
  const conversionRate = 3.2808399;
  // Convert the meters to feet.
  const feet = meters * conversionRate;
  // Round the feet to 2 decimal places.
  return feet.toFixed(2);
}

const TransmissionManager = () => {
  const [step, setStep] = useState('start');

  // we need a way to identify the potree marker when creating a new transmission device. 
  // we do this with a random string
  const [newTransmissionId, setNewTransmissionId] = useState(null);
  const [transmissionList, setTransmissionList] = useState([]);
  
  const appContext = useContext(StoreContext);
  const [potreeViewer, setPotreeViewer] = appContext.potreeViewer;
  const [activePole, setActivePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
  const [dropdownData, setDropdownData] = appContext.dropdownData;
  const [measurementMap, setMeasureMentMap] = appContext.measurementMap;

  const getMeasurement = (measurementLabel) => {
    const marker = measurementMap[measurementLabel];
    const measurement = marker.points[0].position.z - measurementMap['pole_base'].points[0].position.z;
    return metersToFeet(measurement);
  }

  const getTransmissionList = async () => { 
    // now get the list of saved transmissions
    const savedTransmissions = await BVAPI.getTransmissionListByPoleId(activePole.id);
    console.log(savedTransmissions);
    
    // set the state of the component to go to main view. and show transmission list.
    setTransmissionList(savedTransmissions);
  }
  
  const setPointMode = (measurementLabel) => {
    if (!Object.keys(measurementMap).includes(measurementLabel)) { 
      console.log(measurementLabel);
      const measurment = potreeViewer.measuringTool.startInsertion({
        showDistances: false,
        showAngles: false,
        showCoordinates: false,
        showArea: false,
        closed: true,
        maxMarkers: 1,
        name: 'Point'
      });

      measurment.addEventListener('marker_dropped', (event) => {
        // setMarkerPlacement([...potreeViewer.scene.measurements]);
        // map the point object to the ui. 
        setMeasureMentMap(prevMap => ({
          ...prevMap, // Copy existing items
          [measurementLabel]: event.target, // Add/update the new item
        }));

        
      });

    }
  }

  const startNewTransmission = () => {
    console.log("hello world. Start the transmission module.");
    setNewTransmissionId(generateRandomString(6));
    setStep('create-transmission');
  }

  const saveMeasurements = async () => {
    const formData = {
      "existing": null,
      "proposed": null,
      "existing_coord": null,
      "proposed_coord": null,
      "type_id": "Transmission",
      "created_by": activePole.reviewer,
      "updated_by": activePole.reviewer,
      "pole_id": activePole.id
    }

    if (Object.keys(measurementMap).includes(`transmission_existing_${newTransmissionId}`)) { 
      formData['existing'] = getMeasurement(`transmission_existing_${newTransmissionId}`);
      formData['existing_coord'] = vec3ToSerializedString(measurementMap[`transmission_existing_${newTransmissionId}`].points[0].position);
    }

    if (Object.keys(measurementMap).includes(`transmission_proposed_${newTransmissionId}`)) { 
      formData['proposed'] = getMeasurement(`transmission_proposed_${newTransmissionId}`);
      formData['proposed_coord'] = vec3ToSerializedString(measurementMap[`transmission_proposed_${newTransmissionId}`].points[0].position);
    }

    const response = await BVAPI.upsertTransmission(formData);
    console.log("look here for the response on save.");
    console.log(response);

    // now get the list of saved transmissions
    const savedTransmissions = await BVAPI.getTransmissionListByPoleId(activePole.id);
    console.log(savedTransmissions);
    
    // set the state of the component to go to main view. and show transmission list.
    setTransmissionList(savedTransmissions);
    setStep('start');
  }

  useEffect(() => {
    // add all the needed transmission markers to the scene. 
    console.log("Hello, World!");
    getTransmissionList();
    // cleans up 
    // use to remove all the markers from the scene related to transmission. 
    return () => {
      console.log("looks like you need to clean up.");
      // clear the
    }

  }, []);

  useEffect(() => {

  }, [measurementMap]);

  useEffect(() => {
    console.log(dropdownData);
  }, [dropdownData]);

  
  if (step === 'start') {
    return (
      <div className="transmission-manager">  
        {transmissionList.map((transmission) => (
          <div className="attachment-list-item">
            <p>{transmission.existing !== null ? `Existing: ${transmission.existing}ft` : ''}</p>
            <p>{transmission.proposed !== null ? `Proposed:${transmission.proposed}ft` : ''}</p>
            <p>{transmission.type !== null ? `Type: Transmission` : ''}</p>
          </div>
        ))}
        <Button variant="outlined" onClick={startNewTransmission}>Add Transmission</Button>
      </div>
    );
  }

  if (step === 'create-transmission') {
    return (
      <div className="transmission-manager">
        <div className="measure-area">
          <p>existing</p>
          <SquareFootIcon onClick={() => { setPointMode(`transmission_existing_${newTransmissionId}`) }} /> 
          
          <p>
            {Object.keys(measurementMap).includes(`transmission_existing_${newTransmissionId}`) ? getMeasurement(`transmission_existing_${newTransmissionId}`) + 'ft' : ''}
          </p>
        </div>
        <div className="measure-area">
          <p>proposed</p>
          <SquareFootIcon onClick={() => { setPointMode(`transmission_proposed_${newTransmissionId}`) }} /> 
          
          <p>
            {Object.keys(measurementMap).includes(`transmission_proposed_${newTransmissionId}`) ? getMeasurement(`transmission_proposed_${newTransmissionId}`) + 'ft' : ''}
          </p>
        </div>
        <Button variant="outlined" onClick={saveMeasurements}>Save Measurements</Button>
      </div>
    )
  }

};


// on creation we will map a random string to the  measurement block 

// proposed_transmission_sdfsdfsdfsdf  and existing_transmission_sgjhjjh
// once that is create
// thoughts on clearing.. you need to clear the Measure from the potree array and remove its reference from the measure map.

export default TransmissionManager;