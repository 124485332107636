/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from '../../utils/store';
import BVAPI from "../../api";
import Button from '@mui/material/Button';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const vec3ToSerializedString = (vec3) => { 
  const xyz = { x: vec3.x, y: vec3.y, z: vec3.z };
  return JSON.stringify(xyz);
}

const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}


const metersToFeet = (meters) => {
  // The conversion rate from meters to feet.
  const conversionRate = 3.2808399;
  // Convert the meters to feet.
  const feet = meters * conversionRate;
  // Round the feet to 2 decimal places.
  return feet.toFixed(2);
}

const DistributionManager = () => {
  const [step, setStep] = useState('start');
  const [formData, setFormData] = useState({
    type_id: '',
    existing: '',
    proposed: ''
  })

  // we need a way to identify the potree marker when creating a new distribution device. 
  // we do this with a random string
  const [newDistributionId, setNewDistributionId] = useState(null);
  const [distributionList, setDistributionList] = useState([]);


  const appContext = useContext(StoreContext);
  const [potreeViewer, setPotreeViewer] = appContext.potreeViewer;
  const [activePole, setActivePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
  const [dropdownData, setDropdownData] = appContext.dropdownData;
  const [measurementMap, setMeasureMentMap] = appContext.measurementMap;

  const handleDropdownChange = (key, e) => {
    
    console.log("you need to get the value from the data attribute.");
    console.log(e.target);
    
    if (e.target.value > 1000) {
      console.log("is comple equipment. ");
    } else { 
      console.log("is basic equipment");
    }

    setFormData(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }
  
  const getDistributionList = async () => { 
    // now get the list of saved distribution
    const savedDistributions = await BVAPI.getDistributionListByPoleId(activePole.id);
    const extraDistributionEquipment = await BVAPI.getExtraDistributionEquipmentById(activePole.id);
    // // set the state of the component to go to main view. and show distribution list.
    setDistributionList([...savedDistributions, ...extraDistributionEquipment]);
  }

  const getMeasurement = (measurementLabel) => {
    const marker = measurementMap[measurementLabel];
    const measurement = marker.points[0].position.z - measurementMap['pole_base'].points[0].position.z;
    return metersToFeet(measurement);
  }
  
  const setPointMode = (measurementLabel) => {
    if (!Object.keys(measurementMap).includes(measurementLabel)) { 
      console.log(measurementLabel);
      const measurment = potreeViewer.measuringTool.startInsertion({
        showDistances: false,
        showAngles: false,
        showCoordinates: false,
        showArea: false,
        closed: true,
        maxMarkers: 1,
        name: 'Point'
      });

      measurment.addEventListener('marker_dropped', (event) => {
        // setMarkerPlacement([...potreeViewer.scene.measurements]);
        // map the point object to the ui. 
        setMeasureMentMap(prevMap => ({
          ...prevMap, // Copy existing items
          [measurementLabel]: event.target, // Add/update the new item
        }));

        
      });

    }
  }

  const startNewDistribution = () => {
    console.log("Start the distribution module.");
    setNewDistributionId(generateRandomString(6));
    setStep('create-distribution');
  }

  const saveMeasurements = async () => {
    let formDataCollected = {};

    // the 1000 hack.
    if (formData.type_id < 1000) {
      // treat like a normal distribution with existing and proposed. 
      formDataCollected = {
        "existing": null,
        "proposed": null,
        "existing_coord": null,
        "proposed_coord": null,
        "distribution_type_id": formData.type_id,
        "created_by": activePole.reviewer,
        "updated_by": activePole.reviewer,
        "pole_id": activePole.id
      }
      if (Object.keys(measurementMap).includes(`distribution_existing_${newDistributionId}`)) { 
        formDataCollected['existing'] = getMeasurement(`distribution_existing_${newDistributionId}`);
        formDataCollected['existing_coord'] = vec3ToSerializedString(measurementMap[`distribution_existing_${newDistributionId}`].points[0].position);
      }

      if (Object.keys(measurementMap).includes(`distribution_proposed_${newDistributionId}`)) { 
        formDataCollected['proposed'] = getMeasurement(`distribution_proposed_${newDistributionId}`);
        formDataCollected['proposed_coord'] = vec3ToSerializedString(measurementMap[`distribution_proposed_${newDistributionId}`].points[0].position);
      }
      // save the measurements. 
      await BVAPI.upsertDistribution(formDataCollected);
    }

    // the 1000 hack.
    if (formData.type_id > 1000) { 
      // treat as a complex equipment with attached and bottom measurments. 
      // subtract 1000 from id to get the correct id. 
      const correctId = formData.type_id - 1000;
      formDataCollected = {
        // all the measurement values.
        "equipment_attached": null,
        "equipment_attached_coord": null,

        "equipment_bottom": null,
        "equipment_bottom_coord": null,

        "equipment_attached_proposed": null,
        "equipment_attached_proposed_coord": null,

        "equipment_bottom_proposed": null,
        "equipment_bottom_proposed_coord": null,

        "equipment_type_id": correctId,

        "created_by": activePole.reviewer,
        "updated_by": activePole.reviewer,
        "pole_id": activePole.id
      }

      if (Object.keys(measurementMap).includes(`equipment_attached_${newDistributionId}`)) { 
        formDataCollected['equipment_attached'] = getMeasurement(`equipment_attached_${newDistributionId}`);
        formDataCollected['equipment_attached_coord'] = vec3ToSerializedString(measurementMap[`equipment_attached_${newDistributionId}`].points[0].position);
      }

      if (Object.keys(measurementMap).includes(`equipment_bottom_${newDistributionId}`)) { 
        formDataCollected['equipment_bottom'] = getMeasurement(`equipment_bottom_${newDistributionId}`);
        formDataCollected['equipment_bottom_coord'] = vec3ToSerializedString(measurementMap[`equipment_bottom_${newDistributionId}`].points[0].position);
      }

      if (Object.keys(measurementMap).includes(`equipment_attached_proposed_${newDistributionId}`)) { 
        formDataCollected['equipment_attached_proposed'] = getMeasurement(`equipment_attached_proposed__${newDistributionId}`);
        formDataCollected['equipment_attached_proposed_coord'] = vec3ToSerializedString(measurementMap[`equipment_attached_proposed_${newDistributionId}`].points[0].position);
      }

      if (Object.keys(measurementMap).includes(`equipment_bottom_proposed_${newDistributionId}`)) { 
        formDataCollected['equipment_bottom_proposed'] = getMeasurement(`equipment_bottom_proposed_${newDistributionId}`);
        formDataCollected['equipment_bottom_proposed_coord'] = vec3ToSerializedString(measurementMap[`equipment_bottom_proposed_${newDistributionId}`].points[0].position);
      }


      await BVAPI.upsertExtraDistributionEquipment(formDataCollected);
    }
  // upsertExtraDistributionEquipment,
  // getExtraDistributionEquipmentById
    // now get the list of saved distribution
    const savedDistributions = await BVAPI.getDistributionListByPoleId(activePole.id);
    // // get the 'extra' equipment as well. combine the results. 
    const extraDistributionEquipment = await BVAPI.getExtraDistributionEquipmentById(activePole.id);
    // // // set the state of the component to go to main view. and show distribution list.
    setDistributionList([...savedDistributions, ...extraDistributionEquipment]);
    setStep('start');
  }

  useEffect(() => {

    console.log("Hello, World!");
    getDistributionList();
    // cleans up 
    // use to remove all the markers from the scene related to distribution. 
    return () => {
      console.log("looks like you need to clean up.");
      // clear the
    }

  }, []);

  useEffect(() => {

  }, [measurementMap]);

  useEffect(() => {
    console.log(dropdownData);
  }, [dropdownData]);

  
  if (step === 'start') {
    return (
      <div className="distribution-manager">
        {distributionList.map((distribution) => (
          <div className="attachment-list-item">
            <p>{distribution.existing ? `Existing: ${distribution.existing}ft`: ''}</p>
            <p>{distribution.proposed ? `Proposed:${distribution.proposed}ft`: ''}</p>
            <p>{distribution.distribution_type_id ? `Type: ${dropdownData['pole_distribution'].find((obj) => obj.id === distribution.distribution_type_id).label}` : ''}</p> 
            <p>{distribution.equipment_attached ? `Attached: ${distribution.equipment_attached}` : ''}</p>
            <p>{distribution.equipment_bottom ? `Bottom: ${distribution.equipment_bottom}` : ''}</p>
            <p>{distribution.equipment_attached_proposed ? `Attached Proposed: ${distribution.equipment_attached_proposed}` : ''}</p>
            <p>{distribution.equipment_bottom_proposed ? `Bottom Proposed: ${distribution.equipment_bottom_proposed}` : ''}</p>
            <p>{distribution.equipment_type_id ? `Type: ${dropdownData['pole_distribution_equip_typ'].find((obj) => obj.id === distribution.equipment_type_id).type}` : ''}</p> 
          </div>
        ))}

        <Button variant="outlined" onClick={startNewDistribution}>Add Distribution</Button>
      </div>
    );
  }

  if (step === 'create-distribution') { 
    if (formData.type_id < 1000) { 
      return (
        <div className="distribution-manager">
          
          <FormControl fullWidth margin="dense">
            <InputLabel id="demo-simple-select-label">Distribution Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.type_id}
              label="Distribution Type"
              onChange={(e) => { handleDropdownChange('type_id', e)}}
            >
              {dropdownData['pole_distribution'].map((menuItem, index) => (
                <MenuItem key={index} value={menuItem.id} data-equipment="basic" >{menuItem.label}</MenuItem>
              ))}
              {/*
                here we add 1000 as a hack to differentiate the type of equipment. 
                I don't know why this mui react component does not allow data attributes props. Seems crazy to me. 
                will have to roll a custom select component if we want more complex functionality 
                since there is only one other type of equipment configuration the 1000 hack will work for now. 
              */}
              {dropdownData['pole_distribution_equip_typ'].map((menuItem, index) => (
                <MenuItem key={index+1000} value={menuItem.id+1000} >{menuItem.type}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="measure-area">
            <p>existing</p>
            <SquareFootIcon onClick={() => { setPointMode(`distribution_existing_${newDistributionId}`) }} /> 
            
            <p>
              {Object.keys(measurementMap).includes(`distribution_existing_${newDistributionId}`) ? getMeasurement(`distribution_existing_${newDistributionId}`) + 'ft' : ''}
            </p>
          </div>
          <div className="measure-area">
            <p>proposed</p>
            <SquareFootIcon onClick={() => { setPointMode(`distribution_proposed_${newDistributionId}`) }} /> 
            <p>
              {Object.keys(measurementMap).includes(`distribution_proposed_${newDistributionId}`) ? getMeasurement(`distribution_proposed_${newDistributionId}`) + 'ft' : ''}
            </p>
          </div>
          <Button variant="outlined" onClick={saveMeasurements}>Save Measurements</Button>
        </div>
      )      
    }

    if (formData.type_id > 1000) { 
            return (
        <div className="distribution-manager">
          
          <FormControl fullWidth margin="dense">
            <InputLabel id="demo-simple-select-label">Distribution Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.type_id}
              label="Distribution Type"
              onChange={(e) => { handleDropdownChange('type_id', e)}}
            >
              {dropdownData['pole_distribution'].map((menuItem, index) => (
                <MenuItem key={index} value={menuItem.id} data-equipment="basic" >{menuItem.label}</MenuItem>
              ))}
              {/*
                here we add 1000 as a hack to differentiate the type of equipment. 
                I don't know why this mui react component does not allow data attributes props. Seems crazy to me. 
                will have to roll a custom select component if we want more complex functionality 
                since there is only one other type of equipment configuration the 1000 hack will work for now. 
              */}
              {dropdownData['pole_distribution_equip_typ'].map((menuItem, index) => (
                <MenuItem key={index+1000} value={menuItem.id+1000} >{menuItem.type}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="measure-area">
            <p>Equipment Attached</p>
            <SquareFootIcon onClick={() => { setPointMode(`equipment_attached_${newDistributionId}`) }} /> 
      
            <p>
              {Object.keys(measurementMap).includes(`equipment_attached_${newDistributionId}`) ? getMeasurement(`equipment_attached_${newDistributionId}`) + 'ft' : ''}
            </p>
          </div>
          
          <div className="measure-area">
            <p>Equipment Bottom</p>
            <SquareFootIcon onClick={() => { setPointMode(`equipment_bottom_${newDistributionId}`) }} /> 
            <p>
              {Object.keys(measurementMap).includes(`equipment_bottom_${newDistributionId}`) ? getMeasurement(`equipment_bottom_${newDistributionId}`) + 'ft' : ''}
            </p>
          </div>
                
          <div className="measure-area">
            <p>Proposed Equipment Attached</p>
            <SquareFootIcon onClick={() => { setPointMode(`equipment_attached_proposed_${newDistributionId}`) }} /> 
            <p>
              {Object.keys(measurementMap).includes(`equipment_attached_proposed_${newDistributionId}`) ? getMeasurement(`equipment_attached_proposed_${newDistributionId}`) + 'ft' : ''}
            </p>
          </div>  

          <div className="measure-area">
            <p>Proposed Equipment Bottom</p>
            <SquareFootIcon onClick={() => { setPointMode(`equipment_bottom_proposed_${newDistributionId}`) }} /> 
            <p>
              {Object.keys(measurementMap).includes(`equipment_bottom_proposed_${newDistributionId}`) ? getMeasurement(`equipment_bottom_proposed_${newDistributionId}`) + 'ft' : ''}
            </p>
          </div>  
          
          <Button variant="outlined" onClick={saveMeasurements}>Save Measurements</Button>
        </div>
      )
    }

  }

};





export default DistributionManager;