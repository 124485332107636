/* eslint-disable */
const authorizationToken = "effffdb1-3f53-414d-a200-d3a6bb4b5bc4";

const fetchImageryByBounds = async (collectionName, gpsx1, gpsy1, gpsx2, gpsy2) => {
  const headers = new Headers();
  headers.append("authorizationToken", authorizationToken);
  if (gpsx1) {  
    const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_collection_image_data_boundingbox?collection_name=${collectionName}&gpsx1=${gpsx1}&gpsy1=${gpsy1}&gpsx2=${gpsx2}&gpsy2=${gpsy2}`;
    
    const response = await fetch(url);
    if (response.status === 200) {
      const data = await response.json();
      return data['body'];
    } else {
      throw new Error(`API call failed with status code ${response.status}`);
    }
  }
};

const fetchPointcloudsByBounds = async (collectionName, gpsx1, gpsy1, gpsx2, gpsy2) => {
  const headers = new Headers();
  headers.append("authorizationToken", authorizationToken);
  if (gpsx1) {  
    const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pointcloud_data_boundingbox?project_name=${collectionName}&gpsx1=${gpsx1}&gpsy1=${gpsy1}&gpsx2=${gpsx2}&gpsy2=${gpsy2}`;
    
    const response = await fetch(url);
    if (response.status === 200) {
      const data = await response.json();
      return data['body'];
    } else {
      throw new Error(`API call failed with status code ${response.status}`);
    }
  }
};

const fetchPointcloudMetadataJsonUrl = async (collection_name,collection_id,collection_set,point_cloud_filename) => {
  const headers = new Headers();
  headers.append("authorizationToken", authorizationToken);
  const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pointcloud_potree_view?collection_name=${collection_name}&collection_id=${collection_id}&collection_set=${collection_set}&point_cloud_filename=${point_cloud_filename}`;
  const response = await fetch(url);

  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const fetchPolesByBounds = async (projectName, gpsx1, gpsy1, gpsx2, gpsy2) => {
  const headers = new Headers();
  headers.append("authorizationToken", authorizationToken);
  if (gpsx1) {
    const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pole_data_boundingbox?project_name=${projectName}&gpsx1=${gpsx1}&gpsy1=${gpsy1}&gpsx2=${gpsx2}&gpsy2=${gpsy2}`;
    
    const response = await fetch(url);
    if (response.status === 200) {
      const data = await response.json();
      return data['body'];
    } else {
      throw new Error(`API call failed with status code ${response.status}`);
    }
  }
};

const fetchPanoImage = async (imageFolder, imageFilename) => {
  // the url 
  // const projectName = "Palmdale";// we don't need to hard code this. it should already be in the url being passed. 
  const fileLocation = encodeURIComponent(`${imageFolder}${imageFilename}`);
  const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_presigned_image_url?bucket_name=geospatial-nctech&image_url=${fileLocation}`;
  const response = await fetch(url);
  
  if (response.status === 200) {
    const data = await response.json();
    return JSON.parse(data['body']);
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const fetchAdjacentPointcloudTiles = async (pointcloudId, numberOfTiles) => { 
  const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pointcloud_potree_view_by_id?point_cloud_id=${pointcloudId}&adjacent_tiles=${numberOfTiles}`;
  
  const response = await fetch(url);
  
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const fetchProjectList = async () => {
  // const headers = new Headers();
  // headers.append("authorizationToken", authorizationToken);
  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_collection_location_data";
  const response = await fetch(url);
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}


const fetchDropdownValues = async () => {
  const headers = new Headers();
  headers.append("authorizationToken", authorizationToken);
  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pole_dropdown_data";
  const response = await fetch(url);
  if (response.status === 200) {
    const data = await response.json();
    console.log(data);
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const getPoleById = async (id) => { 
  const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pole_data_by_id?pole_id=${id}`;
  const response = await fetch(url);
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const createNewPole = async (data) => { 
  const headers = new Headers();
  // headers.append("authorizationToken", authorizationToken);
  headers.append("Content-Type", 'application/json');

  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/create_pole_data_record";
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data)
  });

  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const updatePole = async (data) => {
  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/update_pole_data_record";

  const headers = {
    'Content-Type': 'application/json',
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data)
  });

  if (response.status === 200) {
    const data = await response.json();
    console.log(data);
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const upsertTransmission = async (data) => { 
  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/upsert_pole_transmission_data";
  const headers = {
    'Content-Type': 'application/json'
  };
  
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify([data])
  });

  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const getTransmissionListByPoleId = async (pole_id) => { 
  const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pole_transmission_data_by_pole_id?pole_id=${pole_id}`;
  const response = await fetch(url);
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const getDistributionListByPoleId = async (pole_id) => { 
  const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pole_distribution_data_by_pole_id?pole_id=${pole_id}`;
  const response = await fetch(url);
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}


const upsertDistribution = async (data) => {
  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/upsert_pole_distribution_data";
  const headers = {
    'Content-Type': 'application/json'
  };
  
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify([data])
  });
  
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const getWirelineCommsByPoleId = async (pole_id) => {
  const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pole_comms_wireline_data_by_pole_id?pole_id=${pole_id}`;
  
  const response = await fetch(url);

  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const upsertWirelineComm = async (data) => {
  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/upsert_pole_comms_wireline_data";
  const headers = {
    'Content-Type': 'application/json'
  };
  
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify([data])
  });
  
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}


const getExtraDistributionEquipmentById = async (pole_id) => {
  const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pole_distribution_equip_data_by_pole_id?pole_id=${pole_id}`;
  
  const response = await fetch(url);

  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}


const upsertExtraDistributionEquipment = async (data) => {
  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/upsert_pole_distribution_equip_data";
  const headers = {
    'Content-Type': 'application/json'
  };
  
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify([data])
  });
  
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}




const getWirelessCommsByPoleId = async (pole_id) => {
  const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_pole_comms_wireless_data_by_pole_id?pole_id=${pole_id}`;
  
  const response = await fetch(url);

  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const upsertWirelessComm = async (data) => {
  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/upsert_pole_comms_wireless_data";
  const headers = {
    'Content-Type': 'application/json'
  };
  
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify([data])
  });
  
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const getGroundCollectionDropdowns = async () => { 
  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/get_ground_collection_request_dropdown";
  const response = await fetch(url);

  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}


const createGroundRequestCollection = async (data) => { 
  const url = "https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/create_ground_collection_request?authorizationToken=effffdb1-3f53-414d-a200-d3a6bb4b5bc4";
  
  const headers = {
    'Content-Type': 'application/json'
  };

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data)
  });
  
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const deletPoleById = async (id) => { 
  const url = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat/delete_pole_by_id?pole_id=${id}`;
  const response = await fetch(url, {
    method: "DELETE",
  });
  if (response.status === 200) {
    const data = await response.json();
    return data['body'];
  } else {
    throw new Error(`API call failed with status code ${response.status}`);
  }
}

const BVAPI = {
  fetchImageryByBounds, // gets all the street imagery metadata in particular area,
  fetchPointcloudsByBounds, // gets all bounding boxes associated with pointcloud files. 
  fetchPanoImage, // gets the actual pre-signed url to a specific image.,
  fetchProjectList, // gets list of projects 
  fetchDropdownValues, // gets the dropdown values for setting data on a pole. ,
  fetchPolesByBounds,
  createNewPole,
  updatePole,
  getPoleById,
  fetchPointcloudMetadataJsonUrl,
  fetchAdjacentPointcloudTiles,
  upsertTransmission,
  getTransmissionListByPoleId,
  upsertDistribution,
  getDistributionListByPoleId,
  getWirelineCommsByPoleId,
  upsertWirelineComm,
  getWirelessCommsByPoleId,
  upsertWirelessComm,
  createGroundRequestCollection,
  getGroundCollectionDropdowns,
  upsertExtraDistributionEquipment,
  getExtraDistributionEquipmentById,
  deletPoleById
}

export default BVAPI;