/* eslint-disable */
import { StoreContext } from '../../utils/store';
import React, { useContext } from "react";

function ProjectInfo() {
  const appContext = useContext(StoreContext); 
  const [activeProject, setActiveProject] = appContext.activeProject;
  
  const clearProject = () => { 
    setActiveProject(null);
  }

  return (
    <div className="active-project">
      <h2>{activeProject.collection_name}</h2>
      {/* <Button variant="contained" onClick={clearProject}>Change Project</Button> */}
    </div>
  );
}

export default ProjectInfo;