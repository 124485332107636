/* eslint-disable */
import React, { useState, useRef, useEffect, useContext } from "react";
import { StoreContext } from '../../utils/store';
import BVAPI from "../../api";


const PotreeViewer = () => {
  const appContext = useContext(StoreContext);
  const [potreeViewer, setPotreeViewer] = appContext.potreeViewer
  const [pointcloud, setPointcloud] = useState(null);
  const [activePointcloud, setActivePointcloud] = appContext.activePointcloud;
  const viewerElemRef = useRef();
  const [adjacentTiles, setAdjacentTiles] = useState(null);
  
  const getAdjacentTiles = async () => { 
    if (activePointcloud) { 
      const theTiles = await BVAPI.fetchAdjacentPointcloudTiles(activePointcloud.pointcloud_id, 6);
      setAdjacentTiles(theTiles);
    }
  }
  
  useEffect(() => { 
    const Potree = window.Potree;
    const viewerElem = viewerElemRef.current;
    let newViewer = new Potree.Viewer(viewerElem);
    setPotreeViewer(newViewer);
    
}, []);

  useEffect(() => { getAdjacentTiles(); }, [activePointcloud]);


  useEffect(() => {
    if (potreeViewer !== null) {
      const viewer = potreeViewer;
      console.log("look here");
      console.log(viewer);

      const Potree = window.Potree;
      const $ = window.$;

      viewer.setEDLEnabled(true);
      viewer.setFOV(60);
      viewer.setPointBudget(6 * 1000 * 1000);
      viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
      viewer.loadSettingsFromURL();
    }
      
  }, [potreeViewer]);


  useEffect(() => { 
    const Potree = window.Potree;
    const viewer = potreeViewer;
    if (activePointcloud !== null && adjacentTiles !== null) {
      console.log(adjacentTiles);
      adjacentTiles.urls.forEach((url) => {
        Potree.loadPointCloud(url).then(e => {
          setPointcloud(e.pointcloud);
          viewer.scene.addPointCloud(e.pointcloud);
          viewer.fitToScreen();
          // controls that daniel likes. same as looq.
          viewer.setControls(viewer.earthControls);
          //default orbit controls
          // viewer.setControls(viewer.orbitControls);
        let material = e.pointcloud.material;
        material.size = .25;
        material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
        material.shape = Potree.PointShape.CIRCLE;
        
        }, e => console.error("ERROR: ", e));
      });

    }
  }, [adjacentTiles])

  return (
    <div id="potree-root">
      <div className="potree-wrapper">
        <div id="potree_render_area" ref={viewerElemRef}>
        </div>
      </div>
    </div>
  );
};

export default PotreeViewer;