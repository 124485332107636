/* eslint-disable */

import React, { useState, useContext, useEffect } from "react";
import Stage from '../features/stage/Stage';
import { StoreContext } from '../utils/store';
import { useLocation, useNavigate } from "react-router-dom";

const PanoImageryOne = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project-id");

  const appContext = useContext(StoreContext);
  const [layout, setLayout] = appContext.layout;
  const [theStage, setTheStage] = appContext.theStage;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;
  const [activeProject, setActiveProject] = appContext.activeProject;
  const [availableProjects, setAvailableProjects] = appContext.availableProjects;
  

  // const fetchDataIfNeeded = async () => { 
  //   if (!availableProjects.length) { 
  //     const projectList = await BVAPI.fetchProjectList();
  //     setAvailableProjects(projectList);
  //     const filteredArray = projectList.filter(obj => obj.collection_name === projectId);
  //     console.log(filteredArray);
  //     await setActiveProject(filteredArray[0]);
  //     setSidebarModules({ s1a: 'map' });
  //   }
    
  // }

  
  useEffect(() => {
    setLayout('layout-full-stage');
    setTheStage('pano');
    // fetchDataIfNeeded();
    // not doing this anymore, but keeping it for future reference. Will probably use somewhere else. 
  }, []);

 
  
  return (
    <div className="stage">
      <Stage />
    </div>
  );
}

export default PanoImageryOne;
