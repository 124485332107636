/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from '../../utils/store';
import BVAPI from "../../api";
import Button from '@mui/material/Button';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const vec3ToSerializedString = (vec3) => { 
  const xyz = { x: vec3.x, y: vec3.y, z: vec3.z };
  return JSON.stringify(xyz);
}

const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}


const metersToFeet = (meters) => {
  // The conversion rate from meters to feet.
  const conversionRate = 3.2808399;
  // Convert the meters to feet.
  const feet = meters * conversionRate;
  // Round the feet to 2 decimal places.
  return feet.toFixed(2);
}

const WirelineCommsManager = () => {
  const [step, setStep] = useState('start');
  const [formData, setFormData] = useState({
    type_id: '',
    existing: '',
    proposed: ''
  })

  // we need a way to identify the potree marker when creating a new wireline device. 
  // we do this with a random string
  const [newWirelineCommsId, setNewWirelineCommsId] = useState(null);
  const [wirelineList, setDistributionList] = useState([]);


  const appContext = useContext(StoreContext);
  const [potreeViewer, setPotreeViewer] = appContext.potreeViewer;
  const [activePole, setActivePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
  const [dropdownData, setDropdownData] = appContext.dropdownData;
  const [measurementMap, setMeasureMentMap] = appContext.measurementMap;

  const handleDropdownChange = (key, e) => {
    setFormData(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }
  
  const getWirelineCommsList = async () => { 
    // now get the list of saved wireline
    const savedDistributions = await BVAPI.getWirelineCommsByPoleId(activePole.id);

    // // set the state of the component to go to main view. and show wireline list.
    setDistributionList(savedDistributions);
  }

  const getMeasurement = (measurementLabel) => {
    const marker = measurementMap[measurementLabel];
    const measurement = marker.points[0].position.z - measurementMap['pole_base'].points[0].position.z;
    return metersToFeet(measurement);
  }
  
  const setPointMode = (measurementLabel) => {
    if (!Object.keys(measurementMap).includes(measurementLabel)) { 
      console.log(measurementLabel);
      const measurment = potreeViewer.measuringTool.startInsertion({
        showDistances: false,
        showAngles: false,
        showCoordinates: false,
        showArea: false,
        closed: true,
        maxMarkers: 1,
        name: 'Point'
      });

      measurment.addEventListener('marker_dropped', (event) => {
        // setMarkerPlacement([...potreeViewer.scene.measurements]);
        // map the point object to the ui. 
        setMeasureMentMap(prevMap => ({
          ...prevMap, // Copy existing items
          [measurementLabel]: event.target, // Add/update the new item
        }));

        
      });

    }
  }

  const startNewWirelineComms = () => {
    console.log("Start the wireline module.");
    setNewWirelineCommsId(generateRandomString(6));
    setStep('create-wireline');
  }

  const saveMeasurements = async () => {

    const formDataCollected = {
      "existing": null,
      "proposed": null,
      "existing_coord": null,
      "proposed_coord": null,
      "comms_type_id": formData.type_id, 
      "comms_owner_id":"1", // I don't understand what this is either. There is not dropdown for this.
      "created_by": activePole.reviewer,
      "updated_by": activePole.reviewer,
      "pole_id": activePole.id,
      "comms_height":"1" // I don't understand what this is. // is this different than existing and proposed? 
    }


    if (Object.keys(measurementMap).includes(`wireline_existing_${newWirelineCommsId}`)) { 
      formDataCollected['existing'] = getMeasurement(`wireline_existing_${newWirelineCommsId}`);
      formDataCollected['existing_coord'] = vec3ToSerializedString(measurementMap[`wireline_existing_${newWirelineCommsId}`].points[0].position);
    }

    if (Object.keys(measurementMap).includes(`wireline_proposed_${newWirelineCommsId}`)) { 
      formDataCollected['proposed'] = getMeasurement(`wireline_proposed_${newWirelineCommsId}`);
      formDataCollected['proposed_coord'] = vec3ToSerializedString(measurementMap[`wireline_proposed_${newWirelineCommsId}`].points[0].position);
    }
    
    
    const response = await BVAPI.upsertWirelineComm(formDataCollected);

    // // now get the list of saved wireline
    const savedWirelineComms = await BVAPI.getWirelineCommsByPoleId(activePole.id);
    
    // // set the state of the component to go to main view. and show wireline list.
    setDistributionList(savedWirelineComms);
    setStep('start');
  }

  useEffect(() => {

    console.log("Hello, World!");
    getWirelineCommsList();
    // cleans up 
    // use to remove all the markers from the scene related to wireline. 
    return () => {
      console.log("looks like you need to clean up.");
      // clear the
    }

  }, []);

  useEffect(() => {

  }, [measurementMap]);

  useEffect(() => {
    console.log(dropdownData);
  }, [dropdownData]);

  
  if (step === 'start') {
    return (
      <div className="comms-manager">
        {wirelineList.map((comm) => (
          <div className="attachment-list-item">
            <p>{comm.existing !== null ? `Existing: ${comm.existing}ft` : ''}</p>
            <p>{comm.proposed !== null ? `Proposed:${comm.proposed}ft` : ''}</p>
            <p>{`Type: ${dropdownData['pole_attachment_type'].find((obj) => obj.id === comm.comms_type_id).attachment_type}` }</p>
          </div>
        ))}
        <Button variant="outlined" onClick={startNewWirelineComms}>Add Wireline Comm</Button>
      </div>
    );
  }

  if (step === 'create-wireline') { 
    return (
      <div className="comms-manager">
        <FormControl fullWidth margin="dense">
          <InputLabel id="demo-simple-select-label">Attachment Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.type_id}
            label="Distribution Type"
            onChange={(e) => { handleDropdownChange('type_id', e)}}
          >
            {dropdownData['pole_attachment_type'].map((menuItem, index) => (
              <MenuItem key={index} value={menuItem.id}>{menuItem.attachment_type}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="measure-area">
          <p>existing</p>
          <SquareFootIcon onClick={() => { setPointMode(`wireline_existing_${newWirelineCommsId}`) }} /> 
          
          <p>
            {Object.keys(measurementMap).includes(`wireline_existing_${newWirelineCommsId}`) ? getMeasurement(`wireline_existing_${newWirelineCommsId}`) + 'ft' : ''}
          </p>
        </div>
        <div className="measure-area">
          <p>proposed</p>
          <SquareFootIcon onClick={() => { setPointMode(`wireline_proposed_${newWirelineCommsId}`) }} /> 
          <p>
            {Object.keys(measurementMap).includes(`wireline_proposed_${newWirelineCommsId}`) ? getMeasurement(`wireline_proposed_${newWirelineCommsId}`) + 'ft' : ''}
          </p>
        </div>


        
        <Button variant="outlined" onClick={saveMeasurements}>Save Measurements</Button>
      </div>
    )
  }

};





export default WirelineCommsManager;



// finishm wireline  
// finish ground collect request 
// show data in sidebar tab for pole when clicked. 