/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from '../../utils/store';
import BVAPI from "../../api";
import Button from '@mui/material/Button';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const vec3ToSerializedString = (vec3) => { 
  const xyz = { x: vec3.x, y: vec3.y, z: vec3.z };
  return JSON.stringify(xyz);
}

const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}


const metersToFeet = (meters) => {
  // The conversion rate from meters to feet.
  const conversionRate = 3.2808399;
  // Convert the meters to feet.
  const feet = meters * conversionRate;
  // Round the feet to 2 decimal places.
  return feet.toFixed(2);
}

const WirelessCommsManager = () => {
  const [step, setStep] = useState('start');

  // we need a way to identify the potree marker when creating a new wireless device. 
  // we do this with a random string
  const [newWirelessCommsId, setNewWirelessCommsId] = useState(null);
  const [wirelessList, setWirelessList] = useState([]);


  const appContext = useContext(StoreContext);
  const [potreeViewer, setPotreeViewer] = appContext.potreeViewer;
  const [activePole, setActivePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
  const [dropdownData, setDropdownData] = appContext.dropdownData;
  const [measurementMap, setMeasureMentMap] = appContext.measurementMap;

  const handleDropdownChange = (key, e) => {
    setFormData(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }
  
  const getWirelessCommsList = async () => { 
    // now get the list of saved wireless
    const savedDistributions = await BVAPI.getWirelessCommsByPoleId(activePole.id);

    // // set the state of the component to go to main view. and show wireless list.
    setWirelessList(savedDistributions);
  }

  const getMeasurement = (measurementLabel) => {
    
    const marker = measurementMap[measurementLabel];
    
    // something weird is happening. This is happening intermitently which makes no sense. 
    // says measurementMap['pole_base'].points is undefined in the error sometimes, but not all the time.
    // this should be impossible since setting a pole_base is required to even be at this screen view.
    // leaving the console logs here so I can catch it next time it happens
    console.log("weird error happening here!!!!! LOOK HERE!!!");
    console.log("there is a problem happeing here in wireless for some reason. makes no sense ");
    console.log("the label:")
    console.log(measurementLabel);
    console.log("the measurement map");
    console.log(measurementMap);
    console.log('the pole base measure object from potree');
    console.log(measurementMap['pole_base']);
    console.log("the measurementMap['pole_base'].points object");
    console.log(measurementMap['pole_base'].points);

    const measurement = marker.points[0].position.z - measurementMap['pole_base'].points[0].position.z;
    return metersToFeet(measurement);
  }
  
  const setPointMode = (measurementLabel) => {
    if (!Object.keys(measurementMap).includes(measurementLabel)) { 
      console.log(measurementLabel);
      const measurment = potreeViewer.measuringTool.startInsertion({
        showDistances: false,
        showAngles: false,
        showCoordinates: false,
        showArea: false,
        closed: true,
        maxMarkers: 1,
        name: 'Point'
      });

      measurment.addEventListener('marker_dropped', (event) => {
        // setMarkerPlacement([...potreeViewer.scene.measurements]);
        // map the point object to the ui. 
        setMeasureMentMap(prevMap => ({
          ...prevMap, // Copy existing items
          [measurementLabel]: event.target, // Add/update the new item
        }));

        
      });

    }
  }

  const startNewWirelineComms = () => {
    console.log("Start the wireless module.");
    setNewWirelessCommsId(generateRandomString(6));
    setStep('create-wireless');
  }

  const saveMeasurements = async () => {

    const formDataCollected = {
      "antenna_top": '',
      "antenna_top_coord": '',
      "antenna_attached": '',
      "antenna_attached_coord": '',
      "antenna_bottom": '',
      "antenna_bottom_coord": '',
      "antenna_top_proposed": '',
      "antenna_top_proposed_coord": '',
      "antenna_attached_proposed": '',
      "antenna_attached_proposed_coord": '',
      "antenna_bottom_proposed": '',
      "antenna_bottom_proposed_coord": '',
      "created_by": activePole.reviewer,
      "updated_by": activePole.reviewer,
      "pole_id": activePole.id,
    }

    if (Object.keys(measurementMap).includes(`antenna_top_${newWirelessCommsId}`)) { 
      formDataCollected['antenna_top'] = getMeasurement(`antenna_top_${newWirelessCommsId}`);
      formDataCollected['antenna_top_coord'] = vec3ToSerializedString(measurementMap[`antenna_top_${newWirelessCommsId}`].points[0].position);
    }

    if (Object.keys(measurementMap).includes(`antenna_attached_${newWirelessCommsId}`)) { 
      formDataCollected['antenna_attached'] = getMeasurement(`antenna_attached_${newWirelessCommsId}`);
      formDataCollected['antenna_attached_coord'] = vec3ToSerializedString(measurementMap[`antenna_attached_${newWirelessCommsId}`].points[0].position);
    }
    
    if (Object.keys(measurementMap).includes(`antenna_bottom_${newWirelessCommsId}`)) { 
      formDataCollected['antenna_bottom'] = getMeasurement(`antenna_bottom_${newWirelessCommsId}`);
      formDataCollected['antenna_bottom_coord'] = vec3ToSerializedString(measurementMap[`antenna_bottom_${newWirelessCommsId}`].points[0].position);
    }

    // handle proposed values
    if (Object.keys(measurementMap).includes(`antenna_top_proposed_${newWirelessCommsId}`)) { 
      formDataCollected['antenna_top_proposed'] = getMeasurement(`antenna_top_proposed_${newWirelessCommsId}`);
      formDataCollected['antenna_top_proposed_coord'] = vec3ToSerializedString(measurementMap[`antenna_top_proposed_${newWirelessCommsId}`].points[0].position);
    }

    if (Object.keys(measurementMap).includes(`antenna_attached_proposed_${newWirelessCommsId}`)) { 
      formDataCollected['antenna_attached_proposed'] = getMeasurement(`antenna_attached_proposed_${newWirelessCommsId}`);
      formDataCollected['antenna_attached_proposed_coord'] = vec3ToSerializedString(measurementMap[`antenna_attached_proposed_${newWirelessCommsId}`].points[0].position);
    }
    
    if (Object.keys(measurementMap).includes(`antenna_bottom_proposed_${newWirelessCommsId}`)) { 
      formDataCollected['antenna_bottom_proposed'] = getMeasurement(`antenna_bottom_proposed_${newWirelessCommsId}`);
      formDataCollected['antenna_bottom_proposed_coord'] = vec3ToSerializedString(measurementMap[`antenna_bottom_proposed_${newWirelessCommsId}`].points[0].position);
    }
    console.log(formDataCollected);
    const response = await BVAPI.upsertWirelessComm(formDataCollected);

    // // // now get the list of saved wireline
    const savedWirelessComms = await BVAPI.getWirelessCommsByPoleId(activePole.id);
    
    // // // set the state of the component to go to main view. and show wireline list.
    setWirelessList(savedWirelessComms);
    setStep('start');
  }

  useEffect(() => {
    getWirelessCommsList();
  }, []);

  useEffect(() => {

  }, [measurementMap]);

  useEffect(() => {
    console.log(dropdownData);
  }, [dropdownData]);

  
  if (step === 'start') {
    return (
      <div className="comms-manager">
        {wirelessList.map((comm) => (
          <div className="attachment-list-item">
            <p>{comm.antenna_top ? `Antenna Top: ${comm.antenna_top}ft` : ''}</p>
            <p>{comm.antenna_attached ? `Antenna Attached:${comm.antenna_attached}ft` : ''}</p>
            <p>{comm.antenna_bottom ? `Antenna Bottom:${comm.antenna_bottom}ft` : ''}</p>
            
            <p>{comm.antenna_top_proposed !== '' ? `Antenna Top Proposed: ${comm.antenna_top_proposed }ft` : ''}</p>
            <p>{comm.antenna_attached_proposed !== '' ? `Antenna Attached Proposed:${comm.antenna_attached_proposed }ft` : ''}</p>
            <p>{comm.antenna_bottom_proposed  !== ''? `Antenna Bottom Proposed:${comm.antenna_bottom_proposed }ft` : ''}</p>
          </div>
        ))}
        <Button variant="outlined" onClick={startNewWirelineComms}>Add Wireless Comm</Button>
      </div>
    );
  }

  if (step === 'create-wireless') { 
    return (
      <div className="comms-manager">
        
        <div className="measure-area">
          <p>existing</p>
          <div className="measurement-unit">
            <p>Antenna Top</p>
            <SquareFootIcon onClick={() => { setPointMode(`antenna_top_${newWirelessCommsId}`) }} /> 
            <p>
              {Object.keys(measurementMap).includes(`antenna_top_${newWirelessCommsId}`) ? getMeasurement(`antenna_top_${newWirelessCommsId}`) + 'ft' : ''}
            </p>  
          </div>
          <div className="measurement-unit">
            <p>Antenna Attached</p>
            <SquareFootIcon onClick={() => { setPointMode(`antenna_attached_${newWirelessCommsId}`) }} /> 
            <p>
              {Object.keys(measurementMap).includes(`antenna_attached_${newWirelessCommsId}`) ? getMeasurement(`antenna_attached_${newWirelessCommsId}`) + 'ft' : ''}
            </p>  
          </div>
          <div className="measurement-unit">
            <p>Antenna Bottom</p>
            <SquareFootIcon onClick={() => { setPointMode(`antenna_bottom_${newWirelessCommsId}`) }} /> 
            <p>
              {Object.keys(measurementMap).includes(`antenna_bottom_${newWirelessCommsId}`) ? getMeasurement(`antenna_bottom_${newWirelessCommsId}`) + 'ft' : ''}
            </p>  
          </div>
            
    
        </div>
        <div className="measure-area">
          <p>proposed</p>
          <div className="measurement-unit">
            <p>Antenna Top</p>
            <SquareFootIcon onClick={() => { setPointMode(`antenna_top_proposed_${newWirelessCommsId}`) }} /> 
            <p>
              {Object.keys(measurementMap).includes(`antenna_top_proposed_${newWirelessCommsId}`) ? getMeasurement(`antenna_top_proposed_${newWirelessCommsId}`) + 'ft' : ''}
            </p>  
          </div>
          <div className="measurement-unit">
            <p>Antenna Attached</p>
            <SquareFootIcon onClick={() => { setPointMode(`antenna_attached_proposed_${newWirelessCommsId}`) }} /> 
            <p>
              {Object.keys(measurementMap).includes(`antenna_attached_proposed_${newWirelessCommsId}`) ? getMeasurement(`antenna_attached_proposed_${newWirelessCommsId}`) + 'ft' : ''}
            </p>  
          </div>
          <div className="measurement-unit">
            <p>Antenna Bottom</p>
            <SquareFootIcon onClick={() => { setPointMode(`antenna_bottom_proposed_${newWirelessCommsId}`) }} /> 
            <p>
              {Object.keys(measurementMap).includes(`antenna_bottom_proposed_${newWirelessCommsId}`) ? getMeasurement(`antenna_bottom_proposed_${newWirelessCommsId}`) + 'ft' : ''}
            </p>  
          </div>
        </div>


        
        <Button variant="outlined" onClick={saveMeasurements}>Save Measurements</Button>
      </div>
    )
  }

};





export default WirelessCommsManager;



// implement with new values that aaron sent 
// implement the pole data panel when you click on a dot. 
// implement request ground collection on the map view. 