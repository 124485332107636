/* eslint-disable */
import React, { useState, useContext } from "react";
import { StoreContext } from './utils/store';
import InteractiveSection from "./features/interactive-section/InteractiveSection";

const SidebarOne = () => {
  const appContext = useContext(StoreContext);
  const [sideBarModules, setSideBarModules] = appContext.sideBarModules;
  
  return (
    <div className="toolbar-one">
      
      <InteractiveSection section={ sideBarModules.s1a } />
      <InteractiveSection section={ sideBarModules.s1b } />
    </div>
  )
}

export default SidebarOne;