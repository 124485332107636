/* eslint-disable */
import React, {useEffect, useState, useContext } from "react";
import { StoreContext } from '../../utils/store';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import proj4 from 'proj4';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import BVAPI from "../../api";
import TransmissionManager from "./TransmissionManager";
import DistributionManager from "./DistributionManager";
import WirelineCommsManager from "./WirelineCommsManager";
import WirelessCommsManager from "./WirelessCommManager";

import RequestGroundCollect from "../request-ground-collection/RequestGroundCollection";

import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';



function getCurrentDateTime() {
  const now = new Date();
  
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}




// returns only the measurements of interest from the pole object.
// a hack to get around the object not having measurements specified in the object.
const filterPoleProperties = (inputObj) => {
  const excludedProperties = [
    "id",
    "project_id",
    "project_name",
    "pole_base",
    "lat",
    "lng",
    "altitude",
    "date_collected",
    "created_by"
  ];
  const filteredObj = { ...inputObj };
  excludedProperties.forEach(property => {
    delete filteredObj[property];
  });
  return filteredObj;
}



const CustomTabPanel = (props)  => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const vec3ToSerializedString = (vec3) => {
  const xyz = { x: vec3.x, y: vec3.y, z: vec3.z };
  return JSON.stringify(xyz);
}

const MeasurementSchema = () => {
  const [activeMeasurementTab, setActiveMeasurementTab] = useState(0);
  
  const [step, setStep] = useState('create-pole');
  const [loading, setLoading] = useState(false);
  const [poleMeasurements, setPoleMeasurements] = useState(null);

  // the measuremnet map maps the items in the potree marker array 
  // to the labels in the measurement tools.
  
  const [poleSchemaForForm, setPoleSchemaForForm] = useState({
    pole_tag_1: '',
    pole_tag_2: '',
    pole_tag_3: '',
    pole_owner: "AT&T",
    pole_material: '',
    pole_class: '',
    pole_species: '',
    pole_ground: '',
    pole_length: '',
    fielder: 'Mike',
    pole_field_notes: '',
    reviewer: ''
  });


  //context state.
  const appContext = useContext(StoreContext);
  const [potreeViewer, setPotreeViewer] = appContext.potreeViewer;
  const [activePole, setActivePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
  const [activePointcloud, setActivePointcloud] = appContext.activePointcloud;
  const [dropdownData, setDropdownData] = appContext.dropdownData;
  const [measurementMap, setMeasureMentMap] = appContext.measurementMap;
  const [potreeMode, setPotreeMode] = appContext.potreeMode;
  const [markerPlacement, setMarkerPlacement] = appContext.markerPlacement;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;
  const [activeProject, setActiveProject] = appContext.activeProject;
  
  const getDropdownData = async () => {
    if (dropdownData === null) {
      const response = await BVAPI.fetchDropdownValues();
      setDropdownData(response);
    }
  }
  

  const getCurrentCameraPosition = () => {
    return potreeViewer.scene.getActiveCamera().position;
  }

  const tabList = [
    {
      id: 'pole',
      label: 'Pole'
    },
    {
      id: 'transmission',
      label: 'Transmission'
    },
    {
      id: 'distribution',
      label: 'Distribution'
    },
    {
      id: 'wireline_comms',
      label: 'Wireline Comms'
    },
    {
      id: 'wireless_comms',
      label: 'Wireless Comms'
    },
    

  ]
  const tabChange = (e, newValue) => { 
    setActiveMeasurementTab(newValue);
  }

  const handleDropdownChange = (key, e) => {
    setPoleSchemaForForm(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }

  const handleTextChange = (key, e) => { 
    setPoleSchemaForForm(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }

  const updateExistingPole = async () => { 
    setLoading(true);
    let updateObject = { ...poleSchemaForForm }
    updateObject.id = activePole.id;
    const response = await BVAPI.updatePole(updateObject);
    // get the pole and update activePole. 
    const updatedPoleData = await BVAPI.getPoleById(activePole.id);
    setActivePole(updatedPoleData[0]);
    setLoading(false);
  }

  const createNewPole = async () => {
    const cameraPosition = getCurrentCameraPosition();
    const now = Date.now();
    // 1. add more data to the scene data. add the pointcloud data. we need everything needed to get the pointcloud. 
    // so that we can use it later when we load the pole. 

    const data = {
      "pole_base": `{ "x": ${markerPlacement[0].points[0].position.x}, "y": ${markerPlacement[0].points[0].position.y}, "z": ${markerPlacement[0].points[0].position.z}  }`,
      "project_id": activeProject.id,
      "project_name": activePointcloud.collection_name,
      "lat": getLatLngOfCoordinate(markerPlacement[0])[1],
      "lng": getLatLngOfCoordinate(markerPlacement[0])[0],
      "date_collected": getCurrentDateTime(),
      "scene_data": `{"cam": ${JSON.stringify(cameraPosition)}, "pc": ${JSON.stringify(activePointcloud)} }`, //associated pointcloud is the pointcloud where the pole was used to create the pole in. // we use this to load the pointcloud when only given a pole id. 
      "created_by": 'cooleys@bv.com',

    };
    setLoading(true);
    const response = await BVAPI.createNewPole(data);
    const poleId = JSON.parse(response).id;
    const activePoleResponse = await BVAPI.getPoleById(poleId); 
    setLoading(false);
    setActivePole(activePoleResponse[0]);
    setStep('update-pole');
  }

  const loadExistingPole = async () => {
    console.log("use the id to load the pole");
  }

  const metersToFeet = (meters) => {
    // The conversion rate from meters to feet.
    const conversionRate = 3.2808399;
    // Convert the meters to feet.
    const feet = meters * conversionRate;
    // Round the feet to 2 decimal places.
    return feet.toFixed(2);
  }

  // use this to get the lat lng of the measurment. 
  const getLatLngOfCoordinate = (measurement) => {
    const proj4Projections = {
      'EPSG:32617': "+proj=utm +zone=17 +datum=WGS84 +units=m +no_defs +type=crs", //  UTM zone 17N
      'EPSG:4978': "+proj=geocent +datum=WGS84 +units=m +no_defs +type=crs", //WGS 84
      'EPSG:4979': "+proj=longlat +datum=WGS84 +no_defs +type=crs", // WGS 84
      'EPSG:32711': "+proj=utm +zone=11 +south +datum=WGS84 +units=m +no_defs +type=crs", // UTM zone 11S
      'EPSG:32611': "+proj=utm +zone=11 +datum=WGS84 +units=m +no_defs +type=crs", // UTM zone 11N 
      'EPSG:4269': "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs +type=crs", // NAD83 full us
      'EPSG:2274': "+proj=lcc +lat_1=36.41666666666666 +lat_2=35.25 +lat_0=34.33333333333334 +lon_0=-86 +x_0=600000 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=0.3048006096012192 +no_defs", //NAD83 TENNESSEE
      'EPSG:32136': "+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=36.4166666666667 +lat_2=35.25 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs +type=crs", //NAD83 ALSO TENNESSEE
      'EPSG:32126': "+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=36.4166666666667 +lat_2=35.25 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs +type=crs", //Remove this. aaron used the typo from your email so it is currently 3126
      'EPSG:26958': "+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +towgs84=-2,0,4,0,0,0,0 +units=us-m +no_defs +type=crs"
    }
    const pointCloudClickCoordinates = [measurement.points[0].position.x, measurement.points[0].position.y ];
    console.log(activePointcloud.crs);
    return proj4(proj4Projections[activePointcloud.crs], '+proj=longlat +datum=WGS84 +ellps=GRS80', pointCloudClickCoordinates);
  }

  const setPointMode = (measurementLabel) => { 
    // only start insertion if the measurement has not yet been taken
    if (!Object.keys(measurementMap).includes(measurementLabel)) {
      setSidebarModules({
        s1a: null, // top sidebar left (toolbar 1)
        s1b: null, //bottom sidebar left (toolbar 1)
        s2a: 'measurements', // top sidebar right (toolbar 2)
        s2b: null,// bottom sidebar right (toolbar 2)
      });
      setPotreeMode(true);
      const measurment = potreeViewer.measuringTool.startInsertion({
        showDistances: false,
        showAngles: false,
        showCoordinates: false,
        showArea: false,
        closed: true,
        maxMarkers: 1,
        name: 'Point'
      });
        
      //here you will define all behaviors for when markers change. 
      measurment.addEventListener('marker_dropped', (event) => {
        setMarkerPlacement([...potreeViewer.scene.measurements]);
        // map the point object to the ui. 
        setMeasureMentMap(prevMap => ({
          ...prevMap, // Copy existing items
          [measurementLabel]: event.target, // Add/update the new item
        }));
        getLatLngOfCoordinate(event.target);


        if (measurementLabel === 'pole_agl') {
          setPoleSchemaForForm(prevState => ({
            ...prevState,
            pole_agl: vec3ToSerializedString(event.target.points[0].position)
          }));
        }

        if (measurementLabel === 'pole_base') { 

          setPoleSchemaForForm(prevState => ({
            ...prevState,
            lat: getLatLngOfCoordinate(event.target)[1],
            lng: getLatLngOfCoordinate(event.target)[0],
            pole_base: vec3ToSerializedString(event.target.points[0].position)
          }));
        }
      });
    }
  }

  const getMeasurement = (measurementLabel) => { 
    const marker = measurementMap[measurementLabel];
    const measurement = marker.points[0].position.z - measurementMap['pole_base'].points[0].position.z;
    return metersToFeet(measurement);
  }
  
  const getMeasurementCoordElevation = (measurementLabel) => { 
    return metersToFeet(measurementMap[measurementLabel].points[0].position.z);
  }
  const getMeasurementSummary = () => {
    // will get any measurements that have been made and return an object with all of them in it. 
    const summary = {}
    Object.keys(measurementMap).forEach((item) => {
      summary[item] = JSON.stringify({
        elevation: measurementMap[item].points[0].position.z,
        height: getMeasurement(item)
      });
    });
    return summary;
  }
  
  const clearPole = () => { 
    potreeViewer.scene.removeAllMeasurements();
    // return state to the original settings. 
    setActivePole(null);
    setPotreeMode(false);
    setMarkerPlacement([]);
    setPoleMeasurements(null);
    setMeasureMentMap({});
    setStep('create-pole');
    // setPointMode('pole_base');
  }
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    console.log("dropdown data should be here now");
    console.log(dropdownData);
  }, [dropdownData]);

  useEffect(() => { 
    console.log("measurementMap has changed.");
    console.log(measurementMap);
    console.log(poleSchemaForForm);
  }, [measurementMap])

  useEffect(() => { 
    if (activePole) { 
      // setup the form data. 
      // add the measurements to the potree environment array
      // marker.points[0]
      // set up markerPlacement
      setStep('update-pole');
    }
  }, [activePole])


  if (potreeViewer !== null) {
    // the interface for creating a pole. 
    if (potreeMode && step === 'create-pole') {
      return (
        <div className="pole-management-wrap">
          <Alert severity="info">Click the base of the pole in the point cloud to set pole location.</Alert>
          <br />
          {loading && <LinearProgress /> }
          {markerPlacement.map((marker, index) => (
            <Card key={index} sx={{ minWidth: 275 }}>
              <CardContent> 
                <div key={index}>
                  <p>Elevation: {metersToFeet(marker.points[0].position.z) }ft</p>
                  <p>Lat: {getLatLngOfCoordinate(marker)[1]}</p>
                  <p>Lng: {getLatLngOfCoordinate(marker)[0]}<br /></p>
                </div>
                <Button variant="outlined" onClick={createNewPole}>Save Pole</Button>
                <br />
              </CardContent>
            </Card>
          ))}
        </div>
      )
    }
    
    // the interface for updating a pole.       
    if (step === 'update-pole') { 
      
      return (
        <div className="pole-management-wrap">
          <span className="new-pole-button"><Button variant="contained"  onClick={clearPole}>Add New Pole</Button></span>
          <p>Pole ID: {activePole.id}</p>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeMeasurementTab} variant="scrollable" scrollButtons allowScrollButtonsMobile onChange={tabChange} aria-label="measurement tabs">  
          { 
            tabList.map((tab, index) => (
              <Tab key={index} label={tab.label} {...a11yProps(index)} />
            ))
          }
          </Tabs>
          </Box>

          
          <CustomTabPanel value={activeMeasurementTab} index={0}>
            {/*hard code the form for main pole schema MAIN POLE SCHEMA SECTION. */}
            <div className="measure-area">
              <SquareFootIcon onClick={() => { setPointMode('pole_agl') }} /> 
              <p>Pole AGL</p>
              <p>
                {Object.keys(measurementMap).includes('pole_agl') ? getMeasurement('pole_agl') + 'ft' : ''}
              </p>
            </div>

            <FormControl fullWidth margin="dense">
              <InputLabel id="demo-simple-select-label">Pole Length</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={poleSchemaForForm.pole_length}
                label="Pole Material"
                onChange={(e) => { handleDropdownChange('pole_length', e)}}
              >
                {dropdownData['pole_height'].map((menuItem, index) => (
                  <MenuItem key={index} value={menuItem.height}>{menuItem.height}</MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <br/>

            <FormControl variant="standard" margin="dense">
              <TextField id="component-simple" label="Reviewer" value={poleSchemaForForm.reviewer} onChange={(e) => { handleTextChange('reviewer', e) }}  />
            </FormControl>
            <br/>

            
            <FormControl variant="standard" margin="dense">
              <TextField id="component-simple" label="Pole Tag 1" value={poleSchemaForForm.pole_tag_1} onChange={(e) => { handleTextChange('pole_tag_1', e) }}  />
            </FormControl>
            <br/>

            <FormControl variant="standard" margin="dense">
              <TextField id="component-simple" label="Pole Tag 2" value={poleSchemaForForm.pole_tag_2} onChange={(e) => { handleTextChange('pole_tag_2', e) }}  />
            </FormControl>
            <br/>
            <FormControl variant="standard" margin="dense">
              <TextField id="component-simple" label="Pole Tag 3" value={poleSchemaForForm.pole_tag_3} onChange={(e) => { handleTextChange('pole_tag_3', e) }}  />
            </FormControl>
            <br/>

            <FormControl fullWidth margin="dense">
              <InputLabel id="demo-simple-select-label">Pole Material</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={poleSchemaForForm.pole_material}
                label="Pole Material"
                onChange={(e) => { handleDropdownChange('pole_material', e)}}
              >
                {dropdownData['pole_material'].map((menuItem, index) => (
                  <MenuItem key={index} value={menuItem.material}>{menuItem.material}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br/>

            <FormControl fullWidth margin="dense">
              <InputLabel id="demo-simple-select-label">Pole Class</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={poleSchemaForForm.pole_class}
                label="Pole Material"
                onChange={(e) => { handleDropdownChange('pole_class', e)}}
              >
                {dropdownData['pole_class'].map((menuItem, index) => (
                  <MenuItem key={index} value={menuItem.class}>{menuItem.class}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br/>

            <FormControl fullWidth margin="dense">
              <InputLabel id="demo-simple-select-label">Pole Species</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={poleSchemaForForm.pole_species}
                label="Pole Material"
                onChange={(e) => { handleDropdownChange('pole_species', e)}}
              >
                {dropdownData['pole_species'].map((menuItem, index) => (
                  <MenuItem key={index} value={menuItem.acronym}>{menuItem.species}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br/>
  
            <FormControl fullWidth margin="dense">
              <InputLabel id="demo-simple-select-label">Pole Ground</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={poleSchemaForForm.pole_ground}
                label="Pole Material"
                onChange={(e) => { handleDropdownChange('pole_ground', e)}}
              >
                {dropdownData['pole_ground'].map((menuItem, index) => (
                  <MenuItem key={index} value={menuItem.ground}>{menuItem.ground}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br/>
            <FormControl fullWidth variant="standard" margin="dense">
              <TextField id="component-simple" label="Pole Field Notes" value={poleSchemaForForm.pole_field_notes} onChange={(e) => { handleTextChange('pole_field_notes', e) }}  />
            </FormControl>
            <br />
            <FormControl disabled variant="standard" margin="dense">
              <InputLabel htmlFor="component-disabled">Pole Owner</InputLabel>
              <Input  id="component-disabled" value={ poleSchemaForForm.pole_owner } />
              <FormHelperText>Disabled</FormHelperText>
            </FormControl>
            <br/>

            <FormControl disabled variant="standard" margin="dense">
              <InputLabel htmlFor="component-disabled">Fielder</InputLabel>
              <Input  id="component-disabled" value={ poleSchemaForForm.fielder } />
              <FormHelperText>Disabled</FormHelperText>
            </FormControl>
            <br/>

            <FormControl disabled variant="standard" margin="dense">
              <InputLabel htmlFor="component-disabled">Base Elevation:</InputLabel>
              <Input  id="component-disabled" value={ getMeasurementCoordElevation('pole_base') } />
              <FormHelperText>Disabled</FormHelperText>
            </FormControl>
            <br/>

            <FormControl disabled variant="standard" margin="dense">
              <InputLabel htmlFor="component-disabled">Lat</InputLabel>
              <Input id="component-disabled" value={ poleSchemaForForm.lat } />
            </FormControl>
            <br/>

            <FormControl disabled variant="standard" margin="dense">
              <InputLabel htmlFor="component-disabled">Lng</InputLabel>
              <Input id="component-disabled" value={ poleSchemaForForm.lng } />
            </FormControl>
            <br />
            
            <Button variant="outlined" onClick={updateExistingPole}>Save Measurements</Button>

          </CustomTabPanel>


          <CustomTabPanel value={activeMeasurementTab} index={1}>
            <TransmissionManager/>
          </CustomTabPanel>


          <CustomTabPanel value={activeMeasurementTab} index={2}>
            <DistributionManager/>
          </CustomTabPanel>
          <CustomTabPanel value={activeMeasurementTab} index={3}>
            <WirelineCommsManager/> 
          </CustomTabPanel>
          <CustomTabPanel value={activeMeasurementTab} index={4}>
            <WirelessCommsManager/>
          </CustomTabPanel>
        </div>
      )
    }

    if (!potreeMode && step !== 'update-pole') { 
      return (
        <div className="start-new-pole-wrap">
          <Button variant="outlined" onClick={() => { setPointMode('pole_base') }}>Set Pole Base</Button>
        </div>
      ) 
    }

  } else { 
    return (
      <div>
      </div>
    );
  }


};

export default MeasurementSchema;
