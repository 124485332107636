/* eslint-disable */
import { StoreContext } from '../../utils/store';
import React, { useContext, useState, useEffect, forwardRef } from "react";
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import BVAPI from '../../api';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';


const metersToFeet = (meters) => {
  // The conversion rate from meters to feet.
  const conversionRate = 3.2808399;
  // Convert the meters to feet.
  const feet = meters * conversionRate;
  // Round the feet to 2 decimal places.
  return feet.toFixed(2);
}

const calculations = (pole) => { 
  console.log("clicked pole data: ");
  console.log(pole);


  const poleBase = JSON.parse(pole.pole_base);

  const theCalculations = {
    pole_agl: null,
    pole_base: metersToFeet(poleBase.z)
  }

  if (pole.pole_agl) { 
    const poleAgl = JSON.parse(pole.pole_agl);
    console.log(poleBase);
    console.log(poleAgl);
    const poleAglDelta = poleAgl.z - poleBase.z;
    console.log(poleAglDelta);
    console.log(poleAgl.z);
    const poleAglFeet = metersToFeet(poleAglDelta);
    theCalculations['pole_agl'] = poleAglFeet;
  }

  return theCalculations;
} 

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function PoleInfoPanel() {
  const appContext = useContext(StoreContext);
  const [activePole, setActivePole] = appContext.activePole;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;
  const [layout, setLayout] = appContext.layout;
  const [loading, setLoading] = useState(true);
  const [dropdownData, setDropdownData] = appContext.dropdownData;

  const [modalOpen, setModalOpen] = useState(false);



  const getDropdownData = async () => {
    if (dropdownData === null) {
      const response = await BVAPI.fetchDropdownValues();
      setDropdownData(response);
    }
  }

  
  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    if (Object.keys(activePole).length) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [activePole]);


  const openModal = () => {
    setModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
  }
  
  
  const closePanel = () => {
    setActivePole(null);
    setSidebarModules({
      s1a: null, // top sidebar left (toolbar 1)
      s1b: null, //bottom sidebar left (toolbar 1)
      s2a: null, // top sidebar right (toolbar 2)
      s2b: null,// bottom sidebar right (toolbar 2)
    });
    setLayout('layout-full-stage ');
  }
  const deletePole = async () => {
    setLoading(true);
    await BVAPI.deletPoleById(activePole.id);
    closePanel();    
  }

  if (!Object.keys(activePole).length) {
    return (
      <div className="active-pole-info-panel">
        <LinearProgress />
        <span className="close-panel-button">
          <Button variant="outlined" className="" onClick={closePanel}>X</Button>
        </span>
      </div>
    );
  } else {
    return (
      <div className="active-pole-info-panel">
        <h1>Pole Id: #{activePole.id}</h1>
        <section className="measurements-display-section">
          <p>{activePole.date_created ? `Date Created: ${activePole.date_created}` : ''}</p>
          <p>{activePole.date_updated ? `Date Created: ${activePole.date_updated}` : ''}</p>
          <p>{activePole.pole_owner ? `Pole Owner: ${activePole.pole_owner}` : ''}</p>
          <p>{activePole.pole_tag_1 ? `Pole Tag 1: ${activePole.pole_tag_1}` : ''}</p>
          <p>{activePole.pole_tag_2 ? `Pole Tag 2: ${activePole.pole_tag_2}` : ''}</p>
          <p>{activePole.pole_tag_3 ? `Pole Tag 3: ${activePole.pole_tag_3}` : ''}</p>
          <p>{activePole.lat ? `Lat: ${activePole.lat}` : ''}</p>
          <p>{activePole.lng ? `Lng: ${activePole.lng}` : ''}</p>
          <p>{activePole.reviewer ? `Reviewer: ${activePole.reviewer}` : ''}</p>
          <p>{activePole.pole_field_notes ? `Field Notes: ${activePole.pole_field_notes}` : ''}</p>
        </section>

        <h2>Measurements</h2>
        <section className="measurements-display-section">
          <p>{activePole.pole_base ? `Pole Base Elevation: ${calculations(activePole).pole_base}ft` : ''}</p>
          <p>{activePole.pole_length ? `Pole Length: ${activePole.pole_length}ft` : ''}</p>
          <p>{activePole.pole_agl ? `Pole AGL: ${calculations(activePole).pole_agl}` : ''}</p>
        </section>
        <h3>Attachments</h3>
        <section className="measurements-display-section">
          <h4>Transmission</h4>
          {activePole.transmission.map((transmission) => (
            <div className="attachment-list-item">
              <p>{transmission.existing !== null ? `Existing: ${transmission.existing}ft` : ''}</p>
              <p>{transmission.proposed !== null ? `Proposed:${transmission.proposed}ft` : ''}</p>
              <p>{transmission.type !== null ? `Type: Transmission` : ''}</p>
            </div>
          ))}
        </section>
        <section className="measurements-display-section">
          <h4>Distribution</h4>

          {activePole.distribution.map((distribution) => (
            <div className="attachment-list-item">
              <p>{distribution.existing ? `Existing: ${distribution.existing}ft` : ''}</p>
              <p>{distribution.proposed ? `Proposed:${distribution.proposed}ft` : ''}</p>
              <p>{distribution.distribution_type_id ? `Type: ${dropdownData['pole_distribution'].find((obj) => obj.id === distribution.distribution_type_id).label}` : ''}</p>
              <p>{distribution.equipment_attached ? `Attached: ${distribution.equipment_attached}ft` : ''}</p>
              <p>{distribution.equipment_bottom ? `Bottom: ${distribution.equipment_bottom}ft` : ''}</p>
              <p>{distribution.equipment_attached_proposed ? `Attached Proposed: ${distribution.equipment_attached_proposed}ft` : ''}</p>
              <p>{distribution.equipment_bottom_proposed ? `Bottom Proposed: ${distribution.equipment_bottom_proposed}ft` : ''}</p>
              <p>{distribution.equipment_type_id ? `Type: ${dropdownData['pole_distribution_equip_typ'].find((obj) => obj.id === distribution.equipment_type_id).type}` : ''}</p>
            </div>
          ))}
        </section>
        <section className="measurements-display-section">
          <h4>Wireless</h4>
          {activePole.wireless.map((comm) => (
            <div className="attachment-list-item">
              <p>{comm.antenna_top ? `Antenna Top: ${comm.antenna_top}ft` : ''}</p>
              <p>{comm.antenna_attached ? `Antenna Attached:${comm.antenna_attached}ft` : ''}</p>
              <p>{comm.antenna_bottom ? `Antenna Bottom:${comm.antenna_bottom}ft` : ''}</p>
                
              <p>{comm.antenna_top_proposed !== '' ? `Antenna Top Proposed: ${comm.antenna_top_proposed}ft` : ''}</p>
              <p>{comm.antenna_attached_proposed !== '' ? `Antenna Attached Proposed:${comm.antenna_attached_proposed}ft` : ''}</p>
              <p>{comm.antenna_bottom_proposed !== '' ? `Antenna Bottom Proposed:${comm.antenna_bottom_proposed}ft` : ''}</p>
            </div>
          ))}
        </section>
        <section className="measurements-display-section">
          <h4>Wireline</h4>
          {activePole.wireline.map((comm) => (
            <div className="attachment-list-item">
              <p>{comm.existing !== null ? `Existing: ${comm.existing}ft` : ''}</p>
              <p>{comm.proposed !== null ? `Proposed:${comm.proposed}ft` : ''}</p>
              <p>{dropdownData !== null && comm.comms_type_id ? `Type: ${dropdownData['pole_attachment_type'].find((obj) => obj.id === comm.comms_type_id).attachment_type}` : ''}</p>
            </div>
              
          ))}
        </section>

          
        <span className="close-panel-button">
          <Button variant="outlined" className="" onClick={closePanel}>X</Button>
        </span>

        <Button variant="outlined" color="error" className="" onClick={openModal}>Delete Pole</Button>
        <br />
        <br />
        <br/>
        <Dialog
            open={modalOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeModal}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Delete This Pole?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
              {`Pole ID #${activePole.id}`}
              Are you sure you want to delete this pole? All associated mesurements will also be deleted.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal}>Don't Delete</Button>
              <Button color="error" onClick={deletePole}>Delete</Button>
            </DialogActions>
          </Dialog>
      </div>
    );
  }
}

export default PoleInfoPanel;