/* eslint-disable */
import React from "react";
import { useEffect, useContext, useState } from "react";
import MapView from '@arcgis/core/views/MapView';
import ArcGISMap from '@arcgis/core/Map';
import initializeMapSettings from './configuration';
import { StoreContext } from '../../utils/store';
import { DeckLayer } from '@deck.gl/arcgis';
import { ScatterplotLayer } from '@deck.gl/layers';
import Chip from '@mui/material/Chip';


// const freeArcBaseMaps = [
//   'satellite',
//   'hybrid',
//   'oceans',
//   'osm',
//   'terrain',
//   'dark-gray-vector',
//   'gray-vector',
//   'streets-vector',
//   'streets-night-vector',
//   'streets-navigation-vector',
//   'topo-vector',`
//   'streets-relief-vector'
// ];



function ArcMap() {

  const appContext = useContext(StoreContext);
  const [ poles, setPoles ] = appContext.poles;
  const [activePole, setActivePole] = appContext.activePole;
  
  
  const mapData = {
    poles,
  }
  // generate initial data for map. 
  const MAP_CONFIGURATION = initializeMapSettings(mapData);
  const layer = new DeckLayer({
    'deck.layers': [
      new ScatterplotLayer({
        data: [
          {position: [0.119, 52.205]}
        ],
        getPosition: d => d.position,
        getColor: [255, 0, 0],
        radiusMinPixels: 20
      }),
      ...MAP_CONFIGURATION.layers
    ]
  });
  const [mapView, setMapView] = useState(null);

  useEffect(() => {
    setMapView(new MapView({
      container: 'mapStage',
      map: new ArcGISMap({
        basemap: 'gray-vector',
        layers: [layer]
      }),
      center: [MAP_CONFIGURATION.initialViewState.longitude, MAP_CONFIGURATION.initialViewState.latitude],
      zoom: MAP_CONFIGURATION.initialViewState.zoom
    }));
  }, []);

  useEffect(() => { 
    console.log(mapView);
    console.log(activePole);
    if (mapView !== null && activePole.coordinates) { 
      mapView.goTo({
      center: [activePole.coordinates[0], activePole.coordinates[1]],
      zoom: 15
    });
    }
  }, [activePole])

  useEffect(() => { 
    console.log("hello world.");
  }, [poles])

  const handleClick = (e) => {
    const basemap = e.currentTarget.getAttribute('data-basemap');
    mapView.map.basemap = basemap;
  };

  return (
    <div className="map-app-wrap">
      <div className="map-container" id="mapStage">
      <div className="map-control-layer-one">
          <Chip label="Satellite" data-basemap="satellite" onClick={handleClick} />
          <Chip label="Gray Streets" data-basemap="gray-vector" variant="outlined" onClick={handleClick} />
      </div>
        
    </div>
    
    </div>
  );
}

export default ArcMap;