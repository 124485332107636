/* eslint-disable */
import { Routes, Route } from "react-router-dom";
import './App.scss';
import Layout from './Layout';
import ProjectList from './features/project-list/ProjectList';
import MapOne from "./app-views/MapOne";
import PanoImageryOne from "./app-views/PanoImageryOne";
import PointCloudOne from './app-views/PointcloudOne';

const App = () => {  
  return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ProjectList />} /> 
          <Route path="map-one" element={<MapOne />} />
          <Route path="pano-imagery-one" element={<PanoImageryOne />} />
          <Route path="pointcloud-one" element={<PointCloudOne />} />
        </Route>
      </Routes>
  )
}

export default App;
