/* eslint-disable */
import React, { useState, useContext } from "react";
import { StoreContext } from './utils/store';

import MeasurementToolbar from "./features/measurement-toolbar/MeasurementToolbar";
import MeasurementSchema from "./features/measurement-schema/MeasurementSchema";
import ProjectInfo from "./features/project-info/ProjectInfo";
import InteractiveSection from "./features/interactive-section/InteractiveSection";

const SidebarTwo = () => {

  const appContext = useContext(StoreContext);
  const [sideBarModules, setSideBarModules] = appContext.sideBarModules;
  
  // Render the component
  return (
    <div className="toolbar-two">
      <div className="t2Top toolModule">
        <InteractiveSection section={sideBarModules.s2a} />
        <br />
        <InteractiveSection section={ sideBarModules.s2b } />
      </div>
      <div className="t2Bottom toolModule">
        {/* <MeasurementToolbar/> */}
        
      </div>
    </div>
  );
};

export default SidebarTwo;