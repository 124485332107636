import { Outlet } from "react-router-dom";
import smallLogo from './static/bv-logo-small.png';
import { StoreContext } from './utils/store';
import React, { useContext } from "react";
import SidebarOne from './SidebarOne';
import SidebarTwo from './SidebarTwo';


const Layout = () => {
  const appContext = useContext(StoreContext);
  const layout = appContext.layout[0];

  return (
    <div className="app">
      <header className="app-header">
        <img src={smallLogo} className="small-logo" alt="bv-logo" />
      </header>
      <div className={`content ${layout}`}>
        <SidebarOne />
        <Outlet />
        <SidebarTwo />
      </div>
    </div>
  )
}


export default Layout;
