/* eslint-disable */
import React, { useContext, useEffect } from "react";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { StoreContext } from '../../utils/store';
import { useNavigate } from "react-router-dom";
import BVAPI from '../../api';

function ProjectList() {
  const navigate = useNavigate();
  const appContext = useContext(StoreContext);
  const [activeProject, setActiveProject] = appContext.activeProject;
  const [availableProjects, setAvailableProjects] = appContext.availableProjects;
  const setLayout = appContext.layout[1];

  const fetchData = async () => {
    const projectList = await BVAPI.fetchProjectList();
    setAvailableProjects(projectList);
  }

  useEffect(() => {
    fetchData();
    setLayout('layout-full-stage');
  }, []);
  
  const listProjects = availableProjects.map(project =>
    <ListItem button key={project.collection_name} onClick={() => {
      setActiveProject(project);
      navigate(`/map-one?project-id=${project.collection_name}`);
      
      // add project parameter to url ?? 
    }}>
        <ListItemText primary={project.collection_name} />
    </ListItem>
  );

  return (
    <div className="project-list">
      <List component="nav" aria-label="projects list">
        { listProjects}
      </List>
    </div>
  );
}

export default ProjectList;