/* eslint-disable */
import { StoreContext } from '../../utils/store';
import React, { useState, useContext } from "react";
import InteractiveSection from '../interactive-section/InteractiveSection';

function Stage() {
  const appContext = useContext(StoreContext); 
  const [theStage, setTheStage] = appContext.theStage;
  return (
    <InteractiveSection section={ theStage } />
  );
}

export default Stage;