/* eslint-disable */
import React, { useEffect, useRef, useCallback, useState, useContext } from "react";
import { StoreContext } from '../../utils/store';
import BVAPI from '../../api/index';
import { useLocation, useNavigate } from "react-router-dom";

const BingMap = ({
    bingMapsKey,
  }) => {
    
  const navigate = useNavigate();
  const appContext = useContext(StoreContext);
  const [activeProject, setActiveProject] = appContext.activeProject;
  const [activePole, setActivePole] = appContext.activePole;
  const [layout, setLayout] = appContext.layout;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;

  const [mapReady, setMapReady] = useState(false);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [scriptInserted, setScriptInserted] = useState(false);

  // will determine if datalayers are shown or not. 
  const [enabledDataLayers, setEnabledDataLayers] = useState({
    panoImagery: true,
    pointclouds: true,
    poles: true
  });

  const [dataLayers, setDataLayers] = useState({
    panoImagery: [],
    pointclouds: [],
    poles: []
  });
  
  const addMapJavasciptToDocument = () => {
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("type", "text/javascript");
      scriptTag.setAttribute(
        "src",
        `https://www.bing.com/api/maps/mapcontrol`
      );
      scriptTag.async = true;
      scriptTag.defer = true;
      document.body.appendChild(scriptTag);

      scriptTag.onload = function () {
        setTimeout(() => {
          if (window.Microsoft && window.Microsoft.Maps) {
            // The script has now been loaded
            // You can now execute your function
            setScriptInserted(true);
          }
          // this is a hack, but I am not sure of a better way right now. 
          // fix later. 
        }, 500);
      };
  }  

  const getMapBounds = (map) => { 
    const bounds = map.current.getBounds();
    return {
      northEast: {
        latitude: bounds.bounds[0],
        longitude: bounds.bounds[1],
      },
      southWest: {
        latitude: bounds.bounds[2],
        longitude: bounds.bounds[3],   
      }
    }

  }

  const getPointcloudBounds = async () => { 
    let thePointcloudBounds = [];
    const { Maps } = window.Microsoft;
    if (enabledDataLayers.pointclouds) {
      const bounds = getMapBounds(map);
      const pointcloudsInArea = await BVAPI.fetchPointcloudsByBounds(
          activeProject.collection_name,
          bounds.northEast.latitude,
          bounds.northEast.longitude,
          bounds.southWest.latitude,
          bounds.southWest.longitude
      );
      
      if (pointcloudsInArea.length < 200) {
        thePointcloudBounds = pointcloudsInArea
      } 
    }
    return thePointcloudBounds;
  }
  
  const getRectangleCoordinates = (minLat, minLon, maxLat, maxLon) => {
    // Create a list of coordinates to represent the rectangle.
    const coordinates = [];

    // Add the bottom left corner of the rectangle.
    coordinates.push([minLat, minLon]);

    // Add the top left corner of the rectangle.
    coordinates.push([minLat, maxLon]);

    // Add the top right corner of the rectangle.
    coordinates.push([maxLat, maxLon]);

    // Add the bottom right corner of the rectangle.
    coordinates.push([maxLat, minLon]);

    return coordinates;
  }

  
  const plotPointcloudBounds = async () => {
    const { Maps } = window.Microsoft;
    const pointcloudsInArea = dataLayers.pointclouds;

    // uses min and max to make a rectangle. 
    pointcloudsInArea.forEach((pointcloud) => {
      const coordsAsRectangle = getRectangleCoordinates(
        pointcloud.minBound_lat,
        pointcloud.minBound_lng,
        pointcloud.maxBound_lat,
        pointcloud.maxBound_lng
      );
      const locations = coordsAsRectangle.map(coord => new Maps.Location(coord[0], coord[1]));
      const pointCloudBoundingBox = new Maps.Polygon(locations);
      
      const theColor = new Maps.Color(.05, 51, 90, 134);
      pointCloudBoundingBox.setOptions({ cursor: 'cross', fillColor: theColor });
      pointCloudBoundingBox.customData = pointcloud;
      map.current.entities.push(pointCloudBoundingBox);
      
      Maps.Events.addHandler(pointCloudBoundingBox, 'click', (e) => {
        const thePointCloudData = e.target.customData;
        window.open(`/pointcloud-one?collection_name=${thePointCloudData.collection_name}&collection_id=${thePointCloudData.collection_id}&crs=${thePointCloudData.crs}&collection_set=${thePointCloudData.collection_set}&point_cloud_filename=${thePointCloudData.point_cloud_filename}&central_lng=${thePointCloudData.central_lng}&pointcloud_id=${thePointCloudData.id}`, '_blank');
      });
      
      
    });
    console.log("these are the entities printed from the bounds plot");
    console.log(map.current.entities);
  }

  //###########################################
  // end handle pointcloud bounding boxes on map



  // handle imagery on map
  //###########################################
  const getPanoImagery = async () => {
    let thePanoImagery = [];
    const { Maps } = window.Microsoft;
    if (enabledDataLayers.panoImagery) {
      const bounds = getMapBounds(map);
      // get the imagery in the area. 
      const imageryInArea = await BVAPI.fetchImageryByBounds(
        activeProject.collection_name,
        bounds.northEast.latitude,
        bounds.northEast.longitude,
        bounds.southWest.latitude,
        bounds.southWest.longitude
      );
    
      if (imageryInArea.length < 200) {
        thePanoImagery = imageryInArea;
      } 
    }
    return thePanoImagery;
  }

  const plotPanoImagery = async () => { 
    const { Maps } = window.Microsoft;
    const imageryInArea = dataLayers.panoImagery;
    imageryInArea.forEach((image) => {
      let pushpin = new Maps.Pushpin({ 
        latitude: image.Latitude_deg,
        longitude: image.Longitude_deg,
        
      }, {color:'blue'});
      pushpin.customData = image;
      map.current.entities.push(pushpin);

      // // add click event 
      Maps.Events.addHandler(pushpin, 'click', () => {
        // add the query parameters here. 
        window.open(`/pano-imagery-one?project-id=${activeProject.collection_name}&image-folder=${pushpin.customData.Frame_folder}&image-filename=${pushpin.customData.Frame_filename}&heading-deg=${pushpin.customData.Heading_deg}`, '_blank');
        // open a new window for the imagary
        
      });
    });
  }
  //###########################################
  // end handle imagery on map


  const getPolesInArea = async () => { 
    let poles = [];
    
    if (enabledDataLayers.poles) { 
      const bounds = getMapBounds(map);
      const polesInArea = await BVAPI.fetchPolesByBounds(
          activeProject.collection_name,
          bounds.northEast.latitude,
          bounds.northEast.longitude,
          bounds.southWest.latitude,
          bounds.southWest.longitude
      );

      // limit at 200 for now. 
      if (polesInArea.length < 200) {
        poles = polesInArea;
      } 
    }

    return poles;
  }


  const getPoleData = async (poleId) => { 
    setActivePole({});
    setSidebarModules({
      s1a: null, // top sidebar left (toolbar 1)
      s1b: null, //bottom sidebar left (toolbar 1)
      s2a: 'pole-info-panel', // top sidebar right (toolbar 2)
      s2b: null,// bottom sidebar right (toolbar 2)
    });
    setLayout('layout-side-right');

    const response = await BVAPI.getPoleById(poleId);

    let pole = response[0];
    // now get the attachments saved to the clicked pole. 
    const transmission  = await BVAPI.getTransmissionListByPoleId(poleId);
    const wireline =  await BVAPI.getWirelineCommsByPoleId(poleId);
    const wireless = await BVAPI.getWirelessCommsByPoleId(poleId);
    const distribution = await BVAPI.getDistributionListByPoleId(poleId);

    // extra distribution equipment
    const extraEquipment = await BVAPI.getExtraDistributionEquipmentById(poleId);


    pole['transmission'] = transmission;
    pole['wireline'] = wireline;
    pole['wireless'] = wireless;
    pole['distribution'] = [...distribution, ...extraEquipment];
    console.log('look here. ');
    console.log(pole);

    setActivePole(pole);

  }

  const plotPolesInArea = async () => { 
    const { Maps } = window.Microsoft;
    const polesInArea = dataLayers.poles;
    polesInArea.forEach((pole) => {
      let pushpin = new Maps.Pushpin({ 
        latitude: pole.lat,
        longitude: pole.lng,
        
      }, {color:'red'});
      pushpin.customData = pole;
      map.current.entities.push(pushpin);

      // // add click event 
      Maps.Events.addHandler(pushpin, 'click', () => {
        // add the query parameters here. 
        // window.open(`/pano-imagery-one?project-id=${activeProject.collection_name}&image-folder=${pushpin.customData.Frame_folder}&image-filename=${pushpin.customData.Frame_filename}`, '_blank');
        // open a new window for the imagary
        console.log("hello world from the pole. ");
        // make the request for data. 
        // set the chosen pole in state.
        // present the data. 
        getPoleData(pole.id);
        
      });
    });
  }


  const getAllDataLayers = async () => { 
    const pointclouds = await getPointcloudBounds();
    const panoImagery = await getPanoImagery();
    const poles = await getPolesInArea();

    setDataLayers({
      pointclouds,
      panoImagery,
      poles
    })
    
  }

  const makeMap = useCallback(async () => {
    const { Maps } = window.Microsoft;
    // only make a new map if one doesn't already exist
    if (!map.current) {
      map.current = new Maps.Map(mapContainer.current, {
        credentials: bingMapsKey,
        disableStreetside: true
      });

      Maps.Events.addHandler(map.current, 'viewchangeend', async function () {
        getAllDataLayers();
      });
      setMapReady(true);
    }
  }, [bingMapsKey, activeProject, navigate]);


  // any time data is changed on the map this will be run. 
  useEffect(() => {
    if (window.Microsoft && map.current) { 
      // clear all entities from the map. 
      map.current.entities.clear();

      plotPointcloudBounds();
      plotPanoImagery();
      plotPolesInArea();
    }
  
  }, [dataLayers]);


  useEffect(() => {
    if (window.Microsoft && window.Microsoft.Maps) {
      makeMap();
    } else {
      addMapJavasciptToDocument();
    }
  }, [makeMap, scriptInserted]);

  useEffect(() => {
    
    // this should be run only when map is ready. 
    if (mapReady) {
      const { Maps } = window.Microsoft;
      map.current.setView({
        center: new Maps.Location(activeProject.lat, activeProject.ln),
        zoom: 17.0,
        mapTypeId: window.Microsoft.Maps.MapTypeId.aerial
      });
    } 
  }, [mapReady]);


  return (
    <div ref={mapContainer} style={{ height: "100%", width: "100%" }}></div>
  );
  
};

export default BingMap;


// this is for  mapping the poles. Probably not useful right now, but could be.. we'll see. 
      // this is for placing poles on the map. Let's worry about this next. 
      // poles.forEach(pole => {
      //   let point = {
      //     latitude: pole.lat,
      //     longitude: pole.lng,
      //     altitude: 0,
      //     altitudeReference: -1
      //   }
      //   let pushpin = new Maps.Pushpin(point, null);
      //   // setup click event for each point. 
      //   // Maps.Events.addHandler(pushpin, 'click', () => {
      //   //   map.current.setView({
      //   //     center: new Maps.Location(pushpin.geometry.y, pushpin.geometry.x),
      //   //     zoom: 15
      //   //   });
      //   // });
      //   map.current.entities.push(pushpin);
      // });