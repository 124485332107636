
import { createContext, useState } from 'react'

export const StoreContext = createContext(null)

const StoreProvider = ({ children }) => {
  const [poles, setPoles] = useState([]);
  const [layout, setLayout] = useState('layout-side-right');
  const [theStage, setTheStage] = useState(null);
  const [dropdownData, setDropdownData] = useState(null);
  const [measurementMap, setMeasureMentMap] = useState({});

  // layout-full-stage 
  // layout-side-left
  // layout-side-right
  // ''  empty string for both sidebars. 
  const [potreeViewer, setPotreeViewer] = useState(null);

  // this is used for setting which component goes in which area of the sidebars. 
  const [sideBarModules, setSidebarModules] = useState({
    s1a: null, // top sidebar left (toolbar 1)
    s1b: null, //bottom sidebar left (toolbar 1)
    s2a: null, // bottom sidebar right (toolbar 2)
    s2b: null,
  });
  const [activeProject, setActiveProject] = useState(null);  
  const [availableProjects, setAvailableProjects] = useState([]);
  const [activePointcloud, setActivePointcloud] = useState(null);
  const [activePole, setActivePole] = useState(null);
  const [potreeMode, setPotreeMode] = useState(false);
  const [markerPlacement, setMarkerPlacement] = useState([]);
  const [groundCollectMode, setGroundCollectMode] = useState(false);

  const store = {
    poles: [poles, setPoles],
    projects: [availableProjects, setAvailableProjects],
    layout: [layout, setLayout], 
    sideBarModules: [sideBarModules, setSidebarModules],
    potreeViewer: [potreeViewer, setPotreeViewer],
    activeProject: [activeProject, setActiveProject],
    availableProjects: [availableProjects, setAvailableProjects],
    theStage: [theStage, setTheStage],
    activePointcloud: [activePointcloud, setActivePointcloud],
    activePole: [activePole, setActivePole],
    dropdownData: [dropdownData, setDropdownData],
    measurementMap: [measurementMap, setMeasureMentMap],
    potreeMode: [potreeMode, setPotreeMode],
    markerPlacement: [markerPlacement, setMarkerPlacement],
    groundCollectMode: [groundCollectMode, setGroundCollectMode]
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export default StoreProvider;