import React from "react";
import MapStage from "../map-stage/MapStage";
import PhotoSphereViewer from "../photo-sphere-viewer/PhotoSphereViewer";
import PotreeViewer from "../potree-viewer/PotreeViewer";
import MeasurementSchema from "../measurement-schema/MeasurementSchema";
import RequestGroundCollection from "../request-ground-collection/RequestGroundCollection";
import PoleInfoPanel from "../pole-info-panel/PoleInfoPanel";
const InteractiveSection = ({ section }) => {

  // render the empty toolbar when passed null
  if (section == null) {
    return (
      <div className="toolbar-module"></div>
    )
  }

  if (section === 'pole-info-panel') {
    return (
      <PoleInfoPanel />
    )
  }

  if (section === 'measurements') {
    return (
      <MeasurementSchema />
    )
  }

  if (section === 'request-ground-collection') {
    return (
      <RequestGroundCollection />
    )
  }

  // render the map when passed map
  if (section === 'map') {
    return (
      <MapStage />
    );
  }

  if (section === 'pano') {
    return (
      <PhotoSphereViewer />
    );
  }

  // render the map when passed map
  if (section === 'pointcloud') {
    return (
      <PotreeViewer />
    );
  }

};

export default InteractiveSection;
